import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { srConfig } from "../../config";
import { KEY_CODES } from "../../utils/index";
import sr from "../../utils/sr";
import usePrefersReducedMotion from "../../hooks/usePrefersReducedMotion";
import mixins from "../../styles/mixins";

const StyledEduSection = styled.section`
	max-width: 700px;

	.inner {
		display: flex;

		@media (max-width: 600px) {
			display: block;
		}

		// Prevent container from jumping
		@media (min-width: 700px) {
			min-height: 340px;
		}
	}
`;

const StyledTabList = styled.div`
	position: relative;
	z-index: 3;
	width: max-content;
	padding: 0;
	margin: 0;
	list-style: none;

	@media (max-width: 600px) {
		display: flex;
		overflow-x: auto;
		width: calc(100% + 100px);
		padding-left: 50px;
		margin-left: -50px;
		margin-bottom: 30px;
	}
	@media (max-width: 480px) {
		width: calc(100% + 50px);
		padding-left: 25px;
		margin-left: -25px;
	}

	li {
		&:first-of-type {
			@media (max-width: 600px) {
				margin-left: 50px;
			}
			@media (max-width: 480px) {
				margin-left: 25px;
			}
		}
		&:last-of-type {
			@media (max-width: 600px) {
				padding-right: 50px;
			}
			@media (max-width: 480px) {
				padding-right: 25px;
			}
		}
	}
`;

const StyledTabButton = styled.button`
	${mixins.link[0]};
	display: flex;
	align-items: center;
	width: 100%;
	height: var(--tab-height);
	padding: 0 20px 2px;
	border-left: 2px solid var(--lightest-navy);
	background-color: transparent;
	color: ${({ isActive }) => (isActive ? "var(--green)" : "var(--slate)")};
	font-family: var(--font-mono);
	font-size: var(--fz-xs);
	text-align: left;
	white-space: nowrap;

	@media (max-width: 768px) {
		padding: 0 15px 2px;
	}
	@media (max-width: 600px) {
		${mixins.flexCenter[0]};
		min-width: 120px;
		padding: 0 15px;
		border-left: 0;
		border-bottom: 2px solid var(--lightest-navy);
		text-align: center;
	}

	&:hover,
	&:focus {
		background-color: var(--light-1-bg);
	}
`;

const StyledHighlight = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: 2px;
	height: var(--tab-height);
	border-radius: var(--border-radius);
	background: var(--green);
	transform: translateY(
		calc(${({ activeTabId }) => activeTabId} * var(--tab-height))
	);
	transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition-delay: 0.1s;

	@media (max-width: 600px) {
		top: auto;
		bottom: 0;
		width: 100%;
		max-width: var(--tab-width);
		height: 2px;
		margin-left: 50px;
		transform: translateX(
			calc(${({ activeTabId }) => activeTabId} * var(--tab-width))
		);
	}
	@media (max-width: 480px) {
		margin-left: 25px;
	}
`;

const StyledTabPanels = styled.div`
	position: relative;
	width: 100%;
	margin-left: 20px;

	@media (max-width: 600px) {
		margin-left: 0;
	}
`;

const StyledTabPanel = styled.div`
	width: 100%;
	height: auto;
	padding: 10px 5px;

	ul {
		${mixins.fancyList[0]};
	}

	h3 {
		margin-bottom: 2px;
		font-size: var(--fz-xxl);
		font-weight: 500;
		line-height: 1.3;

		.place {
			color: var(--green);
		}
	}

	.finish {
		margin-bottom: 25px;
		color: var(--light-slate);
		font-family: var(--font-mono);
		font-size: var(--fz-xs);
	}
`;

function getWindowSize() {
    const {innerWidth: width, innerHeight: height} = window
    return {
        width, height
    }
}

const Edu = () => {
	const EduData = [
		{
			title: "Bachelor of Commerce B.Comm (Honours)",
			place: "TRSM - Ryerson University",
			sf: "TRSM",
			finish: '2021',
			list: ['Focus on how to interperet and respond to customer needs better than the competition', 'Analyzation of consumer trends, competitive activity and other factors', 'Focus on managerial skills and ability to function in a global business environment', 'Understanding of economic models and quantitative skills']
		},
		{
			title: "Computer Software Engineering Immersive Full-Stack",
			place: "General Assembly",
			sf: "GA",
			finish: "2022",
			list: ['A rigorous full-time 12 week course developing skills needed to build dynamic web applications', 'Develop understanding and proficiency in software development libraries, languages and concepts', 'Learned skills needed to communicate, carry out projects from conception to completion, and continue to develop skills post graduation', 'Finished with a 4.0 grade']
		},
		{
			title: null,
			place: "Front-End Development",
			sf: "Front-End",
			finish: null,
			list: ['HTML',"Pug", "CSS", "LESS","SASS", "JavaScript", "TypeScript"]
		},
		{
			title: null,
			place: "Back-End Development",
			sf: "Back-End",
			finish: null,
			list: ["Python", "MongoDB", "Mongoose", "PostgreSQL", "Firebase", "Socket.io"],
		},
		{
			title: null,
			place: "Frameworks",
			sf: 'Frameworks',
			finish: null,
			list: ["Express", 'React','React Native', "Vue.js", 'Node.js', "Django"]
		}
	];

	const [activeTabId, setActiveTabId] = useState(0);
	const [tabFocus, setTabFocus] = useState(null);
	const tabs = useRef([]);
	const revealContainer = useRef(null);
	const prefersReducedMotion = usePrefersReducedMotion();
	const [windowSize, setWindowSize] = useState(getWindowSize())

    useEffect(() => {
        function handleResize() {
            setWindowSize(getWindowSize())
        }
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

	useEffect(() => {
		if (prefersReducedMotion) {
			return;
		}

		sr.reveal(revealContainer.current, srConfig());
	}, [prefersReducedMotion]);

	const focusTab = () => {
		if (tabs.current[tabFocus]) {
			tabs.current[tabFocus].focus();
			return;
		}
		// If we're at the end, go to the start
		if (tabFocus >= tabs.current.length) {
			setTabFocus(0);
		}
		// If we're at the start, move to the end
		if (tabFocus < 0) {
			setTabFocus(tabs.current.length - 1);
		}
	};

	// Only re-run the effect if tabFocus changes
	useEffect(() => focusTab(), []);

	// Focus on tabs when using up & down arrow keys
	const onKeyDown = (e) => {
		switch (e.key) {
			case KEY_CODES.ARROW_UP: {
				e.preventDefault();
				setTabFocus(tabFocus - 1);
				break;
			}

			case KEY_CODES.ARROW_DOWN: {
				e.preventDefault();
				setTabFocus(tabFocus + 1);
				break;
			}

			default: {
				break;
			}
		}
	};

	return (
		<StyledEduSection id="education" ref={revealContainer}>
			<h2 className="numbered-heading">Education &amp; Knowledge</h2>

			<div className="inner">
				<StyledTabList
					role="tablist"
					aria-label="Job tabs"
					onKeyDown={(e) => onKeyDown(e)}
				>
					{EduData &&
						EduData.map((data, i) => {
							return (
								<StyledTabButton
									key={i}
									isActive={activeTabId === i}
									onClick={() => setActiveTabId(i)}
									ref={(el) => (tabs.current[i] = el)}
									id={`tab-${i}`}
									role="tab"
									tabIndex={activeTabId === i ? "0" : "-1"}
									aria-selected={activeTabId === i ? true : false}
									aria-controls={`panel-${i}`}
								>
									<span>{windowSize.width > 600 ? data.place : data.sf}</span>
								</StyledTabButton>
							);
						})}
					<StyledHighlight activeTabId={activeTabId} />
				</StyledTabList>

				<StyledTabPanels>
					{EduData &&
						EduData.map((data, i) => {
							return (
								<CSSTransition
									key={i}
									in={activeTabId === i}
									timeout={250}
									classNames="fade"
								>
									<StyledTabPanel
										id={`panel-${i}`}
										role="tabpanel"
										tabIndex={activeTabId === i ? "0" : "-1"}
										aria-labelledby={`tab-${i}`}
										aria-hidden={activeTabId !== i}
										hidden={activeTabId !== i}
									>
										<h3>
											<span>{data.title}</span>
											<span className="place">
												&nbsp;@&nbsp;
												{data.place}
											</span>
										</h3>

										<p className="finish">{data.finish}</p>

										<div>
											<ul>
												{data.list.map((b, i) => {
													return <li key={i}>{b}</li>
												})}
											</ul>
										</div>
									</StyledTabPanel>
								</CSSTransition>
							);
						})}
				</StyledTabPanels>
			</div>
		</StyledEduSection>
	);
};

export default Edu;
