import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import sr from "../../utils/sr";
import { srConfig } from "../../config";
import Icon from "../icons/icon";
import usePrefersReducedMotion from "../../hooks/usePrefersReducedMotion";
import mixins from "../../styles/mixins";

const StyledProjectsGrid = styled.ul`
	${mixins.resetList[0]};

	a {
		position: relative;
		z-index: 1;
	}
`;

const StyledProject = styled.li`
	position: relative;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(12, 1fr);
	align-items: center;

	@media (max-width: 768px) {
		${mixins.boxShadow[0]};
	}

	&:not(:last-of-type) {
		margin-bottom: 100px;

		@media (max-width: 768px) {
			margin-bottom: 70px;
		}

		@media (max-width: 480px) {
			margin-bottom: 30px;
		}
	}

	&:nth-of-type(odd) {
		.project-content {
			grid-column: 7 / -1;
			text-align: right;

			@media (max-width: 1080px) {
				grid-column: 5 / -1;
			}
			@media (max-width: 768px) {
				grid-column: 1 / -1;
				padding: 40px 40px 30px;
				text-align: left;
			}
			@media (max-width: 480px) {
				padding: 25px 25px 20px;
			}
		}
		.project-tech-list {
			justify-content: flex-end;

			@media (max-width: 768px) {
				justify-content: flex-start;
			}

			li {
				margin: 0 0 5px 20px;
				text-transform: capitalize;

				@media (max-width: 768px) {
					margin: 0 10px 5px 0;
				}
			}
		}
		.project-links {
			justify-content: flex-end;
			margin-left: 0;
			margin-right: -10px;

			@media (max-width: 768px) {
				justify-content: flex-start;
				margin-left: -10px;
				margin-right: 0;
			}
		}
		.project-image {
			grid-column: 1 / 8;

			@media (max-width: 768px) {
				grid-column: 1 / -1;
			}
		}
	}

	.project-content {
		position: relative;
		grid-column: 1 / 7;
		grid-row: 1 / -1;

		@media (max-width: 1080px) {
			grid-column: 1 / 9;
		}

		@media (max-width: 768px) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			grid-column: 1 / -1;
			padding: 40px 40px 30px;
			z-index: 5;
		}

		@media (max-width: 480px) {
			padding: 30px 25px 20px;
		}
	}

	.project-overline {
		margin: 10px 0;
		color: var(--green);
		font-family: var(--font-mono);
		font-size: var(--fz-xs);
		font-weight: 400;
	}

	.project-title {
		color: var(--lightest-slate);
		font-size: clamp(24px, 5vw, 28px);
		text-transform: capitalize;

		@media (min-width: 768px) {
			margin: 0 0 20px;
		}

		@media (max-width: 768px) {
			color: var(--white);

			a {
				position: static;

				&:before {
					content: "";
					display: block;
					position: absolute;
					z-index: 0;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
				}
			}
		}
	}

	.project-description {
		${mixins.boxShadow[0]};
		position: relative;
		z-index: 2;
		padding: 25px;
		border-radius: var(--border-radius);
		background-color: var(--light-1-bg);
		color: var(--light-slate);
		font-size: var(--fz-lg);

		@media (max-width: 768px) {
			padding: 20px 0;
			background-color: transparent;
			box-shadow: none;

			&:hover {
				box-shadow: none;
			}
		}

		a {
			${mixins.inlineLink[0]};
		}

		strong {
			color: var(--white);
			font-weight: normal;
		}
	}

	.project-tech-list {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		z-index: 2;
		margin: 25px 0 10px;
		padding: 0;
		list-style: none;

		li {
			margin: 0 20px 5px 0;
			color: var(--light-slate);
			font-family: var(--font-mono);
			font-size: var(--fz-xs);
			white-space: nowrap;
		}

		@media (max-width: 768px) {
			margin: 10px 0;

			li {
				margin: 0 10px 5px 0;
				color: var(--lightest-slate);
			}
		}
	}

	.project-links {
		display: flex;
		align-items: center;
		position: relative;
		margin-top: 10px;
		margin-left: -10px;
		color: var(--lightest-slate);

		a {
			${mixins.flexCenter[0]};
			padding: 10px;

			&.external {
				svg {
					width: 22px;
					height: 22px;
					margin-top: -4px;
				}
			}

			svg {
				width: 20px;
				height: 20px;
			}
		}

		.cta {
			${mixins.smallButton[0]};
			margin: 10px;
		}
	}

	.project-image {
		grid-column: 6 / -1;
		grid-row: 1 / -1;
		position: relative;
		z-index: 1;

		@media (max-width: 768px) {
			grid-column: 1 / -1;
			height: 100%;
			opacity: 0.25;
		}

		a {
			width: 100%;
			height: 100%;
			border-radius: var(--border-radius);
			vertical-align: middle;
			text-align: center;

			&:hover,
			&:focus {
				background: transparent;
				outline: 0;
				color: #ff6ec7;
				filter: drop-shadow(0 5px 15px #95a2f1);
				transition: all 0.4s ease-in-out;

				&:before,
				.img {
					background: transparent;
					filter: none;
				}
			}

			&:before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 3;
				transition: var(--transition);
				mix-blend-mode: screen;
			}
		}

		.img {
			border-radius: var(--border-radius);
			max-width: 579px;
			max-height: 362px;
			object-fit: scale-down;

			@media (max-width: 768px) {
				object-fit: cover;
				width: auto;
				height: 100%;
				filter: grayscale(100%) contrast(1) brightness(50%);
			}
		}
	}
`;

const Featured = ({featured}) => {
	const revealTitle = useRef(null);
	const revealProjects = useRef([]);
	const prefersReducedMotion = usePrefersReducedMotion();

	const [hover, setHover] = useState(false)

	useEffect(() => {
		if (prefersReducedMotion) {
			return;
		}

		sr.reveal(revealTitle.current, srConfig());
		revealProjects.current.forEach((ref, i) =>
			sr.reveal(ref, srConfig(i * 100))
		);
	}, [prefersReducedMotion]);

	return (
		<section id="projects">
			<h2 className="numbered-heading" ref={revealTitle}>
				Some Things I’ve Built
			</h2>

			<StyledProjectsGrid>
				{featured &&
					featured.map((p, i) => {
                        let name = p.name.replace(/-/g, ' ');
                        let external = p.external;
                        let github = p.github;
                        let desc = p.desc;
                        let tech = p.tech;
                        let image = p.image
						return (
							<StyledProject
								key={p.id}
								ref={(el) => (revealProjects.current[i] = el)}
							>
								<div className="project-content">
									<div>
										<p className="project-overline">Featured Project</p>

										<h3 className="project-title">
											<a href={external}>{name}</a>
										</h3>

										<div className="project-description">
											<p>{desc}</p>
										</div>
										{tech.length && (
											<ul className="project-tech-list">
												{tech.map((tech, i) => (
													<li key={i}>{tech.slice(-3) === '-js' ? tech.replace(/-/g, ".") : tech.replace(/-/g, ' ')}</li>
												))}
											</ul>
										)}

										<div className="project-links">
											{github && (
												<a href={github} aria-label="GitHub Link">
													<Icon name="GitHub" />
												</a>
											)}
											{external && (
												<a
													href={external}
													aria-label="External Link"
													className="external"
												>
													<Icon name="External" />
												</a>
											)}
										</div>
									</div>
								</div>

								<div className="project-image">
									<a href={external ? external : github ? github : "#"}>
										{name === "mymoji" ? (
											<video loop autoPlay muted className={"img " + name}>
												<source
													src={process.env.PUBLIC_URL + image}
												/>
											</video>
										) : name === 'customs' ? (
											<div className={hover ? 'hovering' : null} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>{image}</div>
											) : 
											<img src={process.env.PUBLIC_URL + image} alt={name} className={"img " + name} />
									}
									</a>
								</div>
							</StyledProject>
						);
					})}
			</StyledProjectsGrid>
		</section>
	);
};

export default Featured;
