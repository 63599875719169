import "./App.css";
import About from "./components/sections/About";
import Edu from "./components/sections/Edu";
import Featured from "./components/sections/Featured";
import Hero from "./components/sections/Hero";
import Projects from "./components/sections/Projects";

import styled from "styled-components";
import axios from "axios";
import { useEffect, useState } from "react";
import Contact from "./components/sections/Contact";
import Layout from "./components/Layout";
import { useLocation } from "react-router";
import Customs from "./components/icons/customs";

const StyledMainContainer = styled.main`
	counter-reset: section;
`;

function App() {
const location = useLocation()

	const [featured, setFeatured] = useState([]);
	const [projects, setProjects] = useState([]);

	const url = process.env.REACT_APP_GH_URL;
	const token = process.env.REACT_APP_GH_TOKEN;

	const projectsToFeature = ["mymoji", "fridgy"];

	const fetchGhData = async () => {
		const res = await axios.get(url, token);
		let op = [];
		let fp = [];
		res.data.filter((p) => {
			if (projectsToFeature.includes(p.name)) {
				let project = {
					id: p.id,
					name: p.name,
					external: p.homepage,
					github: p.html_url,
					tech: p.topics,
					desc: p.description,
				};
				if (p.name === projectsToFeature[0]) {
					project.image = "hero-images/mymoji-hero.mov";
				}
				if (p.name === projectsToFeature[1]) {
					project.image = "hero-images/fridgy-hero.png";
				}
				return fp.push(project);
			} else if (p.name !== 'ThomasLawlor17') {
				let project = {
					id: p.id,
					name: p.name,
					external: p.homepage,
					github: p.html_url,
					tech: p.topics,
					desc: p.description,
				};
				return op.push(project);
			}
		});
		fp.reverse();
		if (fp.length < 3) {
			let customs = {id: 999, name: "customs", url: "", github: "https://github.com/ThomasLawlor17", desc: "Creating custom portfoilio websites for UI designers and more, allowing them to show off their work in fast, modern and responsive webpages.", tech: ['react', 'AWS', "gsap", "GitHub-Pages", "Heroku", 'render'], image: <Customs/>}
			fp.push(customs)
		}
		setFeatured(fp);
		setProjects(op);
	};

	useEffect(() => {
		fetchGhData();
	}, []);

	return (
		<>
    <Layout location={location}>
			<StyledMainContainer className="fillHeight">
				<Hero />
				<About id="about" />
				<Edu id="experience"/>
				<Featured featured={featured} id="projects"/>
				<Projects projects={projects} />
				<Contact id="contact"/>
			</StyledMainContainer>
    </Layout>
		</>
	);
}

export default App;
