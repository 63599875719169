import React, { useState } from "react";
import styled from "styled-components";

const StyledSVG = styled.svg`

#laptopGlass1, #laptopGlass2 {
	transition: all 0.4s ease-in-out;
}
.hovering #laptopGlass1 {
	transform: matrix(1, 0, 0, 1, -42, -35);
}
.hovering #laptopGlass2 {
	transform: matrix(1, 0, 0, 1, -92, -66);
}

#gc1 {
	stop-color: #bdbdbd;
}
#gc2 {
	stop-color: #797979;
}
#gc3 {
	stop-color: #a7a7a7;
}
#gc4 {
	stop-color: #797979;
}
#bga1 {
	stop-color: rgb(30,30,30);
}
#bga2 {
	stop-color: rgb(40,40,40);
}
#bga3 {
	stop-color: rgb(60,60,60);
}
#bga4 {
	stop-color: rgb(80,80,80);
}`


const Customs = () => {

	const [hover, setHover] = useState(false)

	return (
		<StyledSVG
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			x="0px"
			y="0px"
			viewBox="0 0 512 512"
			enableBackground="new 0 0 512 512"
			className={hover ? "hovering customs" : "customs"}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<linearGradient
				id="SVGID1"
				gradientUnits="userSpaceOnUse"
				x1="27.0176"
				y1="196.0612"
				x2="298.8857"
				y2="196.0612"
			>
				<stop offset="0" id="bga4"></stop>
				<stop offset="1" id="bga3"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID1)"
				d="M37.8,389.8L37.8,389.8L298.9,15.1c0-7.3-13.4-15.5-19.8-11.9L37.6,142.1  c-6.6,3.8-10.6,10.8-10.6,18.3v211.9C27,379.7,31.2,386.5,37.8,389.8z"
			></path>
			<linearGradient
				id="SVGID2"
				gradientUnits="userSpaceOnUse"
				x1="32.5701"
				y1="196.8114"
				x2="298.8857"
				y2="196.8114"
			>
				<stop offset="0" id="bga2"></stop>
				<stop offset="1" id="bga2"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID2)"
				d="M32.6,388.1l266.1-153.6l0.3-219.3c0-7.3-7.9-11.9-14.2-8.2L43.2,145.8c-6.6,3.8-10.6,10.8-10.6,18.3  L32.6,388.1z"
			></path>
			<linearGradient
				id="SVGID3"
				gradientUnits="userSpaceOnUse"
				x1="32.0322"
				y1="372.798"
				x2="494.8583"
				y2="372.798"
			>
				<stop offset="0" id="bga4"></stop>
				<stop offset="1" id="bga3"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID3)"
				d="M40.5,416l155.2,89.6c11.1,6.4,24.8,6.4,35.9,0l254.6-147c11.4-6.6,11.4-35.6,0-42.2l-153.5-76  c-12.2-7-27.2-7-39.3,0L40.5,373.8C29.2,380.4,29.2,409.4,40.5,416z"
			></path>
			<linearGradient
				id="SVGID4"
				gradientUnits="userSpaceOnUse"
				x1="32.0322"
				y1="360.1223"
				x2="494.8583"
				y2="360.1223"
			>
				<stop offset="0" id="bga2"></stop>
				<stop offset="1" id="bga1"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID4)"
				d="M40.5,403.3l155.2,89.6c11.1,6.4,24.8,6.4,35.9,0l254.6-147c11.4-6.6,11.4-22.9,0-29.5l-153.5-88.7  c-12.2-7-27.2-7-39.3,0l-252.9,146C29.2,380.4,29.2,396.7,40.5,403.3z"
			></path>
			<linearGradient
				id="SVGID5"
				gradientUnits="userSpaceOnUse"
				x1="280.3829"
				y1="397.3991"
				x2="375.15"
				y2="397.3991"
			>
				<stop offset="0" style={{ stopColor: "#B8B8B8" }}></stop>
				<stop offset="1" style={{ stopColor: "#E3E3E3" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID5)"
				d="M282.1,406.2l31.8,18.4c2.3,1.3,5.1,1.3,7.4,0l52.1-30.1c2.3-1.3,2.3-4.7,0-6L342,370.3  c-2.5-1.4-5.6-1.4-8.1,0l-51.8,29.9C279.8,401.5,279.8,404.9,282.1,406.2z"
			></path>
			<linearGradient
				id="SVGID6"
				gradientUnits="userSpaceOnUse"
				x1="280.3829"
				y1="397.3991"
				x2="375.15"
				y2="397.3991"
			>
				<stop offset="0" style={{ stopColor: "#787878" }}></stop>
				<stop offset="1" style={{ stopColor: "#E3E3E3" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID6)"
				d="M282.1,406.2l31.8,18.4c2.3,1.3,5.1,1.3,7.4,0l52.1-30.1c2.3-1.3,2.3-4.7,0-6L342,370.3  c-2.5-1.4-5.6-1.4-8.1,0l-51.8,29.9C279.8,401.5,279.8,404.9,282.1,406.2z"
			></path>
			<path
				opacity="0.5"
				d="M373.4,394.5c0,0,0.1-0.1,0.3-0.2c0.2-0.1,0.4-0.3,0.7-0.7c0.5-0.7,1.1-2,0.4-3.5c-0.2-0.4-0.4-0.7-0.7-1   c-0.3-0.3-0.7-0.5-1.1-0.8c-0.9-0.5-1.8-1-2.8-1.5c-2-1.1-4.2-2.4-6.6-3.7c-4.9-2.8-10.5-6-16.6-9.4c-1.5-0.9-3.1-1.8-4.6-2.6   c-0.8-0.5-1.5-0.8-2.4-1.1c-0.8-0.2-1.7-0.3-2.6-0.2c-0.9,0.1-1.7,0.3-2.5,0.7c-0.8,0.4-1.6,0.9-2.4,1.3c-1.6,0.9-3.2,1.9-4.8,2.8   c-6.5,3.7-12.9,7.4-19,10.9c-6.1,3.4-11.7,6.7-16.6,9.4c-2.4,1.4-4.7,2.6-6.6,3.7c-1,0.6-1.9,1.1-2.8,1.5c-0.4,0.2-0.8,0.5-1.1,0.8   c-0.3,0.3-0.6,0.6-0.7,1c-0.7,1.5-0.1,2.9,0.4,3.5c0.3,0.3,0.5,0.6,0.7,0.7c0.2,0.1,0.3,0.2,0.3,0.2s-0.1-0.1-0.3-0.2   c-0.2-0.1-0.5-0.3-0.7-0.7c-0.3-0.3-0.6-0.8-0.7-1.4c-0.1-0.6-0.1-1.4,0.2-2.2c0.1-0.4,0.4-0.7,0.7-1.1c0.3-0.3,0.7-0.6,1.2-0.8   c0.8-0.5,1.8-1,2.7-1.6c2-1.2,4.2-2.5,6.6-3.9c4.8-2.8,10.4-6.1,16.5-9.7c6-3.5,12.5-7.2,18.9-11c1.6-0.9,3.2-1.9,4.8-2.8   c0.8-0.5,1.6-0.9,2.5-1.4c0.9-0.4,1.8-0.6,2.8-0.7c1-0.1,1.9,0,2.8,0.3c0.9,0.2,1.8,0.7,2.6,1.1c1.6,0.9,3.1,1.8,4.6,2.7   c6,3.5,11.6,6.8,16.5,9.7c2.4,1.4,4.6,2.7,6.6,3.9c1,0.6,1.9,1.1,2.7,1.6c0.4,0.2,0.8,0.5,1.2,0.8c0.3,0.3,0.6,0.7,0.7,1.1   c0.3,0.8,0.3,1.5,0.2,2.2c-0.1,0.6-0.4,1.1-0.7,1.4c-0.3,0.3-0.5,0.6-0.7,0.7C373.5,394.4,373.4,394.5,373.4,394.5z"
			></path>
			<linearGradient
				id="SVGID7"
				gradientUnits="userSpaceOnUse"
				x1="-0.3597"
				y1="284.8801"
				x2="331.0482"
				y2="93.5416"
			>
				<stop offset="0" id="gc2"></stop>
				<stop offset="1" id="gc1"></stop>
			</linearGradient>
			<polygon
				fill="url(#SVGID7)"
				points="43.5,157.3 287.8,18.7 287.8,220.7 43.5,360.9 "
			></polygon>
			<path fill="#FFFFFF" d="M432.4,49.9"></path>

			<image
				overflow="visible"
				opacity="0.75"
				width="62"
				height="45"
				transform="matrix(0.24 0 0 0.24 40.9175 380.9138)"
			></image>
			<polygon
				fill="#A6FF3E"
				points="48.5,388.9 53,386.3 48.3,383.5 43.5,386.3   "
			></polygon>

			<image
				overflow="visible"
				opacity="0.75"
				width="62"
				height="45"
				transform="matrix(0.24 0 0 0.24 48.5739 376.7076)"
			></image>
			<polygon
				fill="#FFDA40"
				points="56.2,384.7 60.7,382.1 55.9,379.3 51.2,382.1   "
			></polygon>

			<path fill="#A6FF3E" d="M-16.4,389.4"></path>
			<path
				opacity="0.31"
				fill="#CCCCCC"
				d="M250.1,488c0,0,3.3-2,9.2-5.5c5.9-3.5,14.2-8.5,24.3-14.5c10.1-5.9,21.8-12.9,34.5-20.2   c12.6-7.3,26.1-15.2,39.6-23c13.5-7.8,27.1-15.5,39.7-22.8c12.7-7.3,24.6-13.9,34.8-19.7c10.2-5.8,18.7-10.5,24.7-13.8   c6-3.3,9.4-5.2,9.4-5.2s-3.3,2-9.2,5.5c-5.9,3.5-14.2,8.5-24.3,14.5c-10.1,5.9-21.8,12.9-34.5,20.2c-12.6,7.3-26.1,15.2-39.6,23   c-13.5,7.8-27.1,15.5-39.7,22.8c-12.7,7.3-24.6,13.9-34.8,19.7c-10.2,5.8-18.7,10.5-24.7,13.8C253.5,486.1,250.1,488,250.1,488z"
			></path>
			<path fill="none" d="M485.5,316.4"></path>

			<path fill="none" d="M331.9,227.8"></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M382.3,312.1l-2.4-1.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l2.4,1.4    c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C383.6,314,383.6,312.8,382.3,312.1z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M72.5,388.7l-3-1.7c-1.3-0.7-3.4-0.7-4.7,0l-11.1,6.4c-1.3,0.7-1.3,2,0,2.7l3,1.7c1.3,0.7,3.4,0.7,4.7,0    l11.1-6.4C73.8,390.6,73.8,389.4,72.5,388.7z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M73.3,384.8l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7c-1.3-0.7-3.4-0.7-4.7,0    l-11.1,6.4C72.1,382.8,72.1,384,73.3,384.8z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M92.9,373.4l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7c-1.3-0.7-3.4-0.7-4.7,0    l-11.1,6.4C91.6,371.5,91.6,372.7,92.9,373.4z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M112.5,362.1l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7c-1.3-0.7-3.4-0.7-4.7,0    l-11.1,6.4C111.2,360.2,111.2,361.4,112.5,362.1z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M132.1,350.8l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7c-1.3-0.7-3.4-0.7-4.7,0    l-11.1,6.4C130.8,348.9,130.8,350.1,132.1,350.8z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M151.7,339.5l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7c-1.3-0.7-3.4-0.7-4.7,0    l-11.1,6.4C150.4,337.6,150.4,338.8,151.7,339.5z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M171.3,328.2l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7c-1.3-0.7-3.4-0.7-4.7,0    l-11.1,6.4C170,326.3,170,327.5,171.3,328.2z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M190.9,316.9l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7c-1.3-0.7-3.4-0.7-4.7,0    l-11.1,6.4C189.6,314.9,189.6,316.2,190.9,316.9z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M210.4,305.6l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7c-1.3-0.7-3.4-0.7-4.7,0    l-11.1,6.4C209.2,303.6,209.2,304.9,210.4,305.6z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M230,294.3l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7c-1.3-0.7-3.4-0.7-4.7,0    l-11.1,6.4C228.7,292.3,228.7,293.5,230,294.3z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M249.6,283l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7c-1.3-0.7-3.4-0.7-4.7,0    l-11.1,6.4C248.3,281,248.3,282.2,249.6,283z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M269.2,271.7l3,1.7c1.3,0.7,3.4,0.7,4.7,0L288,267c1.3-0.7,1.3-2,0-2.7l-3-1.7c-1.3-0.7-3.4-0.7-4.7,0    l-11.1,6.4C267.9,269.7,267.9,270.9,269.2,271.7z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M288.8,260.4l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7c-1.3-0.7-3.4-0.7-4.7,0    l-11.1,6.4C287.5,258.4,287.5,259.6,288.8,260.4z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M308.4,249.1l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7c-1.3-0.7-3.4-0.7-4.7,0    l-11.1,6.4C307.1,247.1,307.1,248.3,308.4,249.1z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M88.7,400l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0    l9.4-5.4C90,401.9,90,400.7,88.7,400z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M97,384.3c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4    c1.3-0.7,1.3-2,0-2.7L97,384.3z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M114.6,387.7l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C111.3,388.4,113.4,388.4,114.6,387.7z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M127.7,363.9l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C131,363.1,128.9,363.1,127.7,363.9z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M145.3,353.7l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C148.7,352.9,146.6,352.9,145.3,353.7z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M163,343.5l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C166.4,342.7,164.3,342.7,163,343.5z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M180.7,333.3l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C184.1,332.5,182,332.5,180.7,333.3z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M198.4,323.1l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C201.8,322.3,199.7,322.3,198.4,323.1z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M216.1,312.8l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C219.4,312.1,217.3,312.1,216.1,312.8z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M233.7,302.6l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C237.1,301.9,235,301.9,233.7,302.6z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M251.4,292.4l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C254.8,291.7,252.7,291.7,251.4,292.4z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M269.1,282.2l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C272.5,281.5,270.4,281.5,269.1,282.2z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M300.9,277.5l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4    c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C302.2,279.4,302.2,278.2,300.9,277.5z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M295,269.9l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l35.9-20.7c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0    L295,267.2C293.8,268,293.8,269.2,295,269.9z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M327.9,281.8c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0    l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7L327.9,281.8z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M310.3,278.4l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C313.6,277.6,311.5,277.6,310.3,278.4z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M292.6,302.2c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0    l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7L292.6,302.2z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M274.9,298.8l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C278.3,298.1,276.2,298.1,274.9,298.8z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M257.2,309l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C260.6,308.3,258.5,308.3,257.2,309z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M239.5,319.2l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C242.9,318.5,240.8,318.5,239.5,319.2z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M221.9,329.4l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C225.2,328.7,223.1,328.7,221.9,329.4z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M204.2,339.6l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C207.6,338.9,205.5,338.9,204.2,339.6z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M186.5,349.9l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C189.9,349.1,187.8,349.1,186.5,349.9z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M168.8,360.1l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C172.2,359.3,170.1,359.3,168.8,360.1z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M151.1,370.3l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7    l-9.4-5.4C154.5,369.5,152.4,369.5,151.1,370.3z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M124,388.6l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0    l-9.4,5.4C122.7,386.7,122.7,387.9,124,388.6z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M129,396.6l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-32.5,18.8c-1.3,0.7-1.3,2,0,2.7l9.4,5.4    c1.3,0.7,3.4,0.7,4.7,0l32.5-18.8C130.3,398.6,130.3,397.4,129,396.6z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M338.7,261.9l-2.5,1.5c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l0.2-0.1l15.5,9    c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-27.3-15.8c-1.3-0.7-3.4-0.7-4.7,0L338.7,261.9L338.7,261.9z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M353,290.6l9.4-5.4c1.3-0.7,1.3-2,0-2.7L353,277c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C349.6,291.4,351.7,291.4,353,290.6z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M344.8,292.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4    c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C346.1,294.6,346.1,293.4,344.8,292.7z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M317.7,311l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C314.3,311.8,316.4,311.8,317.7,311z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M300,321.2l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C296.6,322,298.7,322,300,321.2z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M282.3,331.5l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C278.9,332.2,281,332.2,282.3,331.5z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M264.6,341.7l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C261.2,342.4,263.3,342.4,264.6,341.7z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M246.9,351.9l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C243.5,352.6,245.6,352.6,246.9,351.9z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M229.2,362.1l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C225.9,362.8,228,362.8,229.2,362.1z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M211.6,372.3l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C208.2,373,210.3,373,211.6,372.3z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M193.9,382.5l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C190.5,383.2,192.6,383.2,193.9,382.5z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M162.1,387.3l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0    l-9.4,5.4C160.8,385.3,160.8,386.5,162.1,387.3z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M144.4,397.5l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0    l-9.4,5.4C143.1,395.5,143.1,396.7,144.4,397.5z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M150.3,405l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-35.9,20.7c-1.3,0.7-1.3,2,0,2.7l9.4,5.4    c1.3,0.7,3.4,0.7,4.7,0l35.9-20.7C151.6,406.9,151.6,405.7,150.3,405z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M141.4,430.4l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4    c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C142.7,432.3,142.7,431.1,141.4,430.4z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M149.7,414.7c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0    l9.4-5.4c1.3-0.7,1.3-2,0-2.7L149.7,414.7z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M167.3,418.1l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C163.9,418.9,166.1,418.9,167.3,418.1z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M185,407.9l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C181.6,408.6,183.7,408.6,185,407.9z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M202.7,397.7l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C199.3,398.4,201.4,398.4,202.7,397.7z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M220.4,387.5l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C217,388.2,219.1,388.2,220.4,387.5z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M238.1,377.3l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C234.7,378,236.8,378,238.1,377.3z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M255.7,367.1l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C252.4,367.8,254.5,367.8,255.7,367.1z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M273.4,356.9l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C270,357.6,272.1,357.6,273.4,356.9z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M291.1,346.6l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C287.7,347.4,289.8,347.4,291.1,346.6z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M308.8,336.4l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C305.4,337.2,307.5,337.2,308.8,336.4z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M326.5,326.2l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C323.1,327,325.2,327,326.5,326.2z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M344.2,316l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7    l9.4,5.4C340.8,316.8,342.9,316.8,344.2,316z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M397.7,282.4l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-35.9,20.7c-1.3,0.7-1.3,2,0,2.7l9.4,5.4    c1.3,0.7,3.4,0.7,4.7,0l35.9-20.7C399,284.4,399,283.1,397.7,282.4z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M159.3,440.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4    c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C160.6,442.7,160.6,441.4,159.3,440.7z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M177,430.5l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0    l9.4-5.4C178.2,432.4,178.2,431.2,177,430.5z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M194.6,420.3l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4    c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C195.9,422.2,195.9,421,194.6,420.3z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M212.3,410.1l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4    c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C213.6,412,213.6,410.8,212.3,410.1z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M230,399.9l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0    l9.4-5.4C231.3,401.8,231.3,400.6,230,399.9z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M353.8,328.4l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4    c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C355.1,330.4,355.1,329.1,353.8,328.4z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M318.4,348.8l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-80.2,46.3c-1.3,0.7-1.3,2,0,2.7l9.4,5.4    c1.3,0.7,3.4,0.7,4.7,0l80.2-46.3C319.7,350.8,319.7,349.6,318.4,348.8z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M336.1,338.6l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4    c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C337.4,340.6,337.4,339.4,336.1,338.6z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M399.2,302.4l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4    c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C400.5,304.3,400.5,303.1,399.2,302.4z"
			></path>
			<path
				opacity="0.75"
				fill="#1C1C1C"
				d="M415.7,292.8l-2.4-1.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l2.4,1.4    c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C417,294.8,417,293.5,415.7,292.8z"
			></path>
			<linearGradient
				id="SVGID8"
				gradientUnits="userSpaceOnUse"
				x1="66.1232"
				y1="407.94"
				x2="417.8627"
				y2="300.3182"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID8)"
				d="M382.3,310.3l-2.4-1.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l2.4,1.4     c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C383.6,312.3,383.6,311.1,382.3,310.3z"
			></path>
			<linearGradient
				id="SVGID9"
				gradientUnits="userSpaceOnUse"
				x1="61.0232"
				y1="391.2719"
				x2="412.7628"
				y2="283.6501"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID9)"
				d="M72.5,386.9l-3-1.7c-1.3-0.7-3.4-0.7-4.7,0l-11.1,6.4c-1.3,0.7-1.3,2,0,2.7l3,1.7     c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4C73.8,388.9,73.8,387.7,72.5,386.9z"
			></path>
			<linearGradient
				id="SVGID10"
				gradientUnits="userSpaceOnUse"
				x1="59.5359"
				y1="386.4108"
				x2="411.2754"
				y2="278.789"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID10)"
				d="M73.3,383l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7     c-1.3-0.7-3.4-0.7-4.7,0l-11.1,6.4C72.1,381.1,72.1,382.3,73.3,383z"
			></path>
			<linearGradient
				id="SVGID11"
				gradientUnits="userSpaceOnUse"
				x1="58.0485"
				y1="381.5497"
				x2="409.7881"
				y2="273.9279"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID11)"
				d="M92.9,371.7l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7     c-1.3-0.7-3.4-0.7-4.7,0L92.9,369C91.6,369.7,91.6,371,92.9,371.7z"
			></path>
			<linearGradient
				id="SVGID12"
				gradientUnits="userSpaceOnUse"
				x1="56.5612"
				y1="376.6886"
				x2="408.3007"
				y2="269.0668"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID12)"
				d="M112.5,360.4l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7     c-1.3-0.7-3.4-0.7-4.7,0l-11.1,6.4C111.2,358.4,111.2,359.6,112.5,360.4z"
			></path>
			<linearGradient
				id="SVGID13"
				gradientUnits="userSpaceOnUse"
				x1="55.0738"
				y1="371.8275"
				x2="406.8134"
				y2="264.2057"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID13)"
				d="M132.1,349.1l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7     c-1.3-0.7-3.4-0.7-4.7,0l-11.1,6.4C130.8,347.1,130.8,348.3,132.1,349.1z"
			></path>
			<linearGradient
				id="SVGID14"
				gradientUnits="userSpaceOnUse"
				x1="53.5865"
				y1="366.9664"
				x2="405.326"
				y2="259.3446"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID14)"
				d="M151.7,337.8l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7     c-1.3-0.7-3.4-0.7-4.7,0l-11.1,6.4C150.4,335.8,150.4,337,151.7,337.8z"
			></path>
			<linearGradient
				id="SVGID15"
				gradientUnits="userSpaceOnUse"
				x1="52.0991"
				y1="362.1053"
				x2="403.8387"
				y2="254.4835"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID15)"
				d="M171.3,326.5l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7     c-1.3-0.7-3.4-0.7-4.7,0l-11.1,6.4C170,324.5,170,325.7,171.3,326.5z"
			></path>
			<linearGradient
				id="SVGID16"
				gradientUnits="userSpaceOnUse"
				x1="50.6118"
				y1="357.2442"
				x2="402.3513"
				y2="249.6224"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID16)"
				d="M190.9,315.2l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7     c-1.3-0.7-3.4-0.7-4.7,0l-11.1,6.4C189.6,313.2,189.6,314.4,190.9,315.2z"
			></path>
			<linearGradient
				id="SVGID17"
				gradientUnits="userSpaceOnUse"
				x1="49.1244"
				y1="352.3831"
				x2="400.864"
				y2="244.7613"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID17)"
				d="M210.4,303.8l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7     c-1.3-0.7-3.4-0.7-4.7,0l-11.1,6.4C209.2,301.9,209.2,303.1,210.4,303.8z"
			></path>
			<linearGradient
				id="SVGID18"
				gradientUnits="userSpaceOnUse"
				x1="47.637"
				y1="347.5219"
				x2="399.3766"
				y2="239.9001"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID18)"
				d="M230,292.5l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7     c-1.3-0.7-3.4-0.7-4.7,0l-11.1,6.4C228.7,290.6,228.7,291.8,230,292.5z"
			></path>
			<linearGradient
				id="SVGID19"
				gradientUnits="userSpaceOnUse"
				x1="46.1497"
				y1="342.6608"
				x2="397.8892"
				y2="235.039"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID19)"
				d="M249.6,281.2l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7     c-1.3-0.7-3.4-0.7-4.7,0l-11.1,6.4C248.3,279.3,248.3,280.5,249.6,281.2z"
			></path>
			<linearGradient
				id="SVGID20"
				gradientUnits="userSpaceOnUse"
				x1="44.6623"
				y1="337.7997"
				x2="396.4019"
				y2="230.1779"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID20)"
				d="M269.2,269.9l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7     c-1.3-0.7-3.4-0.7-4.7,0l-11.1,6.4C267.9,268,267.9,269.2,269.2,269.9z"
			></path>
			<linearGradient
				id="SVGID21"
				gradientUnits="userSpaceOnUse"
				x1="43.175"
				y1="332.9386"
				x2="394.9145"
				y2="225.3168"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID21)"
				d="M288.8,258.6l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7     c-1.3-0.7-3.4-0.7-4.7,0l-11.1,6.4C287.5,256.7,287.5,257.9,288.8,258.6z"
			></path>
			<linearGradient
				id="SVGID22"
				gradientUnits="userSpaceOnUse"
				x1="41.6876"
				y1="328.0775"
				x2="393.4272"
				y2="220.4557"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID22)"
				d="M308.4,247.3l3,1.7c1.3,0.7,3.4,0.7,4.7,0l11.1-6.4c1.3-0.7,1.3-2,0-2.7l-3-1.7     c-1.3-0.7-3.4-0.7-4.7,0l-11.1,6.4C307.1,245.3,307.1,246.6,308.4,247.3z"
			></path>
			<linearGradient
				id="SVGID23"
				gradientUnits="userSpaceOnUse"
				x1="64.7056"
				y1="403.3068"
				x2="416.4451"
				y2="295.685"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID23)"
				d="M88.7,398.2l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C90,400.2,90,399,88.7,398.2z"
			></path>
			<linearGradient
				id="SVGID24"
				gradientUnits="userSpaceOnUse"
				x1="63.3628"
				y1="398.9184"
				x2="415.1024"
				y2="291.2966"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID24)"
				d="M97,382.6c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0     l9.4-5.4c1.3-0.7,1.3-2,0-2.7L97,382.6z"
			></path>
			<linearGradient
				id="SVGID25"
				gradientUnits="userSpaceOnUse"
				x1="62.0201"
				y1="394.5299"
				x2="413.7596"
				y2="286.9081"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID25)"
				d="M114.6,385.9l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C111.3,386.7,113.4,386.7,114.6,385.9z"
			></path>
			<linearGradient
				id="SVGID26"
				gradientUnits="userSpaceOnUse"
				x1="60.6773"
				y1="390.1414"
				x2="412.4169"
				y2="282.5197"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID26)"
				d="M127.7,362.1l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C131,361.4,128.9,361.4,127.7,362.1z"
			></path>
			<linearGradient
				id="SVGID27"
				gradientUnits="userSpaceOnUse"
				x1="59.3346"
				y1="385.753"
				x2="411.0742"
				y2="278.1312"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID27)"
				d="M145.3,351.9l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C148.7,351.2,146.6,351.2,145.3,351.9z"
			></path>
			<linearGradient
				id="SVGID28"
				gradientUnits="userSpaceOnUse"
				x1="57.9919"
				y1="381.3645"
				x2="409.7314"
				y2="273.7427"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID28)"
				d="M163,341.7l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C166.4,341,164.3,341,163,341.7z"
			></path>
			<linearGradient
				id="SVGID29"
				gradientUnits="userSpaceOnUse"
				x1="56.6491"
				y1="376.9761"
				x2="408.3887"
				y2="269.3543"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID29)"
				d="M180.7,331.5l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C184.1,330.8,182,330.8,180.7,331.5z"
			></path>
			<linearGradient
				id="SVGID30"
				gradientUnits="userSpaceOnUse"
				x1="55.3064"
				y1="372.5876"
				x2="407.0459"
				y2="264.9658"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID30)"
				d="M198.4,321.3l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C201.8,320.6,199.7,320.6,198.4,321.3z"
			></path>
			<linearGradient
				id="SVGID31"
				gradientUnits="userSpaceOnUse"
				x1="53.9637"
				y1="368.1992"
				x2="405.7032"
				y2="260.5774"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID31)"
				d="M216.1,311.1l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C219.4,310.3,217.3,310.3,216.1,311.1z"
			></path>
			<linearGradient
				id="SVGID32"
				gradientUnits="userSpaceOnUse"
				x1="52.6209"
				y1="363.8107"
				x2="404.3605"
				y2="256.1889"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID32)"
				d="M233.7,300.9l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C237.1,300.1,235,300.1,233.7,300.9z"
			></path>
			<linearGradient
				id="SVGID33"
				gradientUnits="userSpaceOnUse"
				x1="51.2782"
				y1="359.4222"
				x2="403.0177"
				y2="251.8004"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID33)"
				d="M251.4,290.7l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C254.8,289.9,252.7,289.9,251.4,290.7z"
			></path>
			<linearGradient
				id="SVGID34"
				gradientUnits="userSpaceOnUse"
				x1="49.9354"
				y1="355.0338"
				x2="401.675"
				y2="247.412"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID34)"
				d="M269.1,280.5l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C272.5,279.7,270.4,279.7,269.1,280.5z"
			></path>
			<linearGradient
				id="SVGID35"
				gradientUnits="userSpaceOnUse"
				x1="48.5927"
				y1="350.6453"
				x2="400.3322"
				y2="243.0235"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID35)"
				d="M300.9,275.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C302.2,277.7,302.2,276.4,300.9,275.7z"
			></path>
			<linearGradient
				id="SVGID36"
				gradientUnits="userSpaceOnUse"
				x1="46.2465"
				y1="342.9771"
				x2="397.986"
				y2="235.3553"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID36)"
				d="M295,268.2l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l35.9-20.7c1.3-0.7,1.3-2,0-2.7l-9.4-5.4     c-1.3-0.7-3.4-0.7-4.7,0L295,265.5C293.8,266.2,293.8,267.4,295,268.2z"
			></path>
			<linearGradient
				id="SVGID_37_"
				gradientUnits="userSpaceOnUse"
				x1="51.0447"
				y1="358.6591"
				x2="402.7842"
				y2="251.0373"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_37_)"
				d="M327.9,280c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0     l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7L327.9,280z"
			></path>
			<linearGradient
				id="SVGID_38_"
				gradientUnits="userSpaceOnUse"
				x1="52.3874"
				y1="363.0476"
				x2="404.127"
				y2="255.4258"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_38_)"
				d="M310.3,276.6l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C313.6,275.9,311.5,275.9,310.3,276.6z"
			></path>
			<linearGradient
				id="SVGID_39_"
				gradientUnits="userSpaceOnUse"
				x1="53.7302"
				y1="367.436"
				x2="405.4697"
				y2="259.8142"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_39_)"
				d="M292.6,300.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4     c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7L292.6,300.4z"
			></path>
			<linearGradient
				id="SVGID_40_"
				gradientUnits="userSpaceOnUse"
				x1="55.0729"
				y1="371.8245"
				x2="406.8124"
				y2="264.2027"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_40_)"
				d="M274.9,297.1l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C278.3,296.3,276.2,296.3,274.9,297.1z"
			></path>
			<linearGradient
				id="SVGID_41_"
				gradientUnits="userSpaceOnUse"
				x1="56.4156"
				y1="376.2129"
				x2="408.1552"
				y2="268.5911"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_41_)"
				d="M257.2,307.3l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C260.6,306.5,258.5,306.5,257.2,307.3z"
			></path>
			<linearGradient
				id="SVGID_42_"
				gradientUnits="userSpaceOnUse"
				x1="57.7584"
				y1="380.6014"
				x2="409.4979"
				y2="272.9796"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_42_)"
				d="M239.5,317.5l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C242.9,316.7,240.8,316.7,239.5,317.5z"
			></path>
			<linearGradient
				id="SVGID_43_"
				gradientUnits="userSpaceOnUse"
				x1="59.1011"
				y1="384.9899"
				x2="410.8406"
				y2="277.368"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_43_)"
				d="M221.9,327.7l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C225.2,326.9,223.1,326.9,221.9,327.7z"
			></path>
			<linearGradient
				id="SVGID_44_"
				gradientUnits="userSpaceOnUse"
				x1="60.4438"
				y1="389.3783"
				x2="412.1834"
				y2="281.7565"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_44_)"
				d="M204.2,337.9l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C207.6,337.2,205.5,337.2,204.2,337.9z"
			></path>
			<linearGradient
				id="SVGID_45_"
				gradientUnits="userSpaceOnUse"
				x1="61.7866"
				y1="393.7668"
				x2="413.5261"
				y2="286.145"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_45_)"
				d="M186.5,348.1l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C189.9,347.4,187.8,347.4,186.5,348.1z"
			></path>
			<linearGradient
				id="SVGID_46_"
				gradientUnits="userSpaceOnUse"
				x1="63.1293"
				y1="398.1552"
				x2="414.8689"
				y2="290.5334"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_46_)"
				d="M168.8,358.3l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C172.2,357.6,170.1,357.6,168.8,358.3z"
			></path>
			<linearGradient
				id="SVGID_47_"
				gradientUnits="userSpaceOnUse"
				x1="64.4721"
				y1="402.5437"
				x2="416.2116"
				y2="294.9219"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_47_)"
				d="M151.1,368.5l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4     c1.3-0.7,1.3-2,0-2.7l-9.4-5.4C154.5,367.8,152.4,367.8,151.1,368.5z"
			></path>
			<linearGradient
				id="SVGID_48_"
				gradientUnits="userSpaceOnUse"
				x1="65.8148"
				y1="406.9322"
				x2="417.5543"
				y2="299.3103"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_48_)"
				d="M124,386.9l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4     c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4C122.7,384.9,122.7,386.1,124,386.9z"
			></path>
			<linearGradient
				id="SVGID_49_"
				gradientUnits="userSpaceOnUse"
				x1="68.0962"
				y1="414.3884"
				x2="419.8357"
				y2="306.7666"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_49_)"
				d="M129,394.9l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-32.5,18.8c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l32.5-18.8C130.3,396.8,130.3,395.6,129,394.9z"
			></path>
			<linearGradient
				id="SVGID_50_"
				gradientUnits="userSpaceOnUse"
				x1="51.717"
				y1="360.8563"
				x2="403.4565"
				y2="253.2345"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_50_)"
				d="M338.7,260.2l-2.5,1.5c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l0.2-0.1l15.5,9     c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-27.3-15.8c-1.3-0.7-3.4-0.7-4.7,0L338.7,260.2L338.7,260.2z"
			></path>
			<linearGradient
				id="SVGID_51_"
				gradientUnits="userSpaceOnUse"
				x1="55.2675"
				y1="372.4605"
				x2="407.007"
				y2="264.8387"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_51_)"
				d="M353,288.9l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C349.6,289.6,351.7,289.6,353,288.9z"
			></path>
			<linearGradient
				id="SVGID_52_"
				gradientUnits="userSpaceOnUse"
				x1="56.6102"
				y1="376.849"
				x2="408.3498"
				y2="269.2272"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_52_)"
				d="M344.8,290.9l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C346.1,292.9,346.1,291.7,344.8,290.9z"
			></path>
			<linearGradient
				id="SVGID_53_"
				gradientUnits="userSpaceOnUse"
				x1="57.953"
				y1="381.2374"
				x2="409.6925"
				y2="273.6156"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_53_)"
				d="M317.7,309.3l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C314.3,310,316.4,310,317.7,309.3z"
			></path>
			<linearGradient
				id="SVGID_54_"
				gradientUnits="userSpaceOnUse"
				x1="59.2957"
				y1="385.6259"
				x2="411.0352"
				y2="278.0041"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_54_)"
				d="M300,319.5l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C296.6,320.2,298.7,320.2,300,319.5z"
			></path>
			<linearGradient
				id="SVGID_55_"
				gradientUnits="userSpaceOnUse"
				x1="60.6385"
				y1="390.0143"
				x2="412.378"
				y2="282.3925"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_55_)"
				d="M282.3,329.7l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C278.9,330.4,281,330.4,282.3,329.7z"
			></path>
			<linearGradient
				id="SVGID_56_"
				gradientUnits="userSpaceOnUse"
				x1="61.9812"
				y1="394.4028"
				x2="413.7207"
				y2="286.781"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_56_)"
				d="M264.6,339.9l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C261.2,340.7,263.3,340.7,264.6,339.9z"
			></path>
			<linearGradient
				id="SVGID_57_"
				gradientUnits="userSpaceOnUse"
				x1="63.3239"
				y1="398.7913"
				x2="415.0635"
				y2="291.1695"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_57_)"
				d="M246.9,350.1l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C243.5,350.9,245.6,350.9,246.9,350.1z"
			></path>
			<linearGradient
				id="SVGID_58_"
				gradientUnits="userSpaceOnUse"
				x1="64.6667"
				y1="403.1797"
				x2="416.4062"
				y2="295.5579"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_58_)"
				d="M229.2,360.3l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C225.9,361.1,228,361.1,229.2,360.3z"
			></path>
			<linearGradient
				id="SVGID_59_"
				gradientUnits="userSpaceOnUse"
				x1="66.0094"
				y1="407.5682"
				x2="417.749"
				y2="299.9464"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_59_)"
				d="M211.6,370.5l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C208.2,371.3,210.3,371.3,211.6,370.5z"
			></path>
			<linearGradient
				id="SVGID_60_"
				gradientUnits="userSpaceOnUse"
				x1="67.3521"
				y1="411.9566"
				x2="419.0917"
				y2="304.3348"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_60_)"
				d="M193.9,380.8l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C190.5,381.5,192.6,381.5,193.9,380.8z"
			></path>
			<linearGradient
				id="SVGID_61_"
				gradientUnits="userSpaceOnUse"
				x1="68.6949"
				y1="416.3451"
				x2="420.4344"
				y2="308.7233"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_61_)"
				d="M162.1,385.5l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4     c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4C160.8,383.6,160.8,384.8,162.1,385.5z"
			></path>
			<linearGradient
				id="SVGID_62_"
				gradientUnits="userSpaceOnUse"
				x1="70.0376"
				y1="420.7336"
				x2="421.7772"
				y2="313.1118"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_62_)"
				d="M144.4,395.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4     c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4C143.1,393.8,143.1,395,144.4,395.7z"
			></path>
			<linearGradient
				id="SVGID_63_"
				gradientUnits="userSpaceOnUse"
				x1="72.3839"
				y1="428.4018"
				x2="424.1234"
				y2="320.78"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_63_)"
				d="M150.3,403.2l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-35.9,20.7c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l35.9-20.7C151.6,405.2,151.6,404,150.3,403.2z"
			></path>
			<linearGradient
				id="SVGID_64_"
				gradientUnits="userSpaceOnUse"
				x1="77.7283"
				y1="445.8689"
				x2="429.4678"
				y2="338.2471"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_64_)"
				d="M141.4,428.6l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C142.7,430.6,142.7,429.4,141.4,428.6z"
			></path>
			<linearGradient
				id="SVGID_65_"
				gradientUnits="userSpaceOnUse"
				x1="76.3855"
				y1="441.4804"
				x2="428.1251"
				y2="333.8586"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_65_)"
				d="M149.7,413c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4c1.3,0.7,3.4,0.7,4.7,0     l9.4-5.4c1.3-0.7,1.3-2,0-2.7L149.7,413z"
			></path>
			<linearGradient
				id="SVGID_66_"
				gradientUnits="userSpaceOnUse"
				x1="75.0428"
				y1="437.092"
				x2="426.7823"
				y2="329.4702"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_66_)"
				d="M167.3,416.4l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C163.9,417.1,166.1,417.1,167.3,416.4z"
			></path>
			<linearGradient
				id="SVGID_67_"
				gradientUnits="userSpaceOnUse"
				x1="73.7001"
				y1="432.7035"
				x2="425.4396"
				y2="325.0817"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_67_)"
				d="M185,406.2l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C181.6,406.9,183.7,406.9,185,406.2z"
			></path>
			<linearGradient
				id="SVGID_68_"
				gradientUnits="userSpaceOnUse"
				x1="72.3573"
				y1="428.3151"
				x2="424.0969"
				y2="320.6933"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_68_)"
				d="M202.7,395.9l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C199.3,396.7,201.4,396.7,202.7,395.9z"
			></path>
			<linearGradient
				id="SVGID_69_"
				gradientUnits="userSpaceOnUse"
				x1="71.0146"
				y1="423.9266"
				x2="422.7541"
				y2="316.3048"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_69_)"
				d="M220.4,385.7l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C217,386.5,219.1,386.5,220.4,385.7z"
			></path>
			<linearGradient
				id="SVGID_70_"
				gradientUnits="userSpaceOnUse"
				x1="69.6719"
				y1="419.5381"
				x2="421.4114"
				y2="311.9164"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_70_)"
				d="M238.1,375.5l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C234.7,376.3,236.8,376.3,238.1,375.5z"
			></path>
			<linearGradient
				id="SVGID_71_"
				gradientUnits="userSpaceOnUse"
				x1="68.3291"
				y1="415.1497"
				x2="420.0687"
				y2="307.5279"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_71_)"
				d="M255.7,365.3l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C252.4,366.1,254.5,366.1,255.7,365.3z"
			></path>
			<linearGradient
				id="SVGID_72_"
				gradientUnits="userSpaceOnUse"
				x1="66.9864"
				y1="410.7612"
				x2="418.7259"
				y2="303.1394"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_72_)"
				d="M273.4,355.1l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C270,355.8,272.1,355.8,273.4,355.1z"
			></path>
			<linearGradient
				id="SVGID_73_"
				gradientUnits="userSpaceOnUse"
				x1="65.6436"
				y1="406.3728"
				x2="417.3832"
				y2="298.751"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_73_)"
				d="M291.1,344.9l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C287.7,345.6,289.8,345.6,291.1,344.9z"
			></path>
			<linearGradient
				id="SVGID_74_"
				gradientUnits="userSpaceOnUse"
				x1="64.3009"
				y1="401.9843"
				x2="416.0404"
				y2="294.3625"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_74_)"
				d="M308.8,334.7l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C305.4,335.4,307.5,335.4,308.8,334.7z"
			></path>
			<linearGradient
				id="SVGID_75_"
				gradientUnits="userSpaceOnUse"
				x1="62.9582"
				y1="397.5959"
				x2="414.6977"
				y2="289.9741"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_75_)"
				d="M326.5,324.5l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C323.1,325.2,325.2,325.2,326.5,324.5z"
			></path>
			<linearGradient
				id="SVGID_76_"
				gradientUnits="userSpaceOnUse"
				x1="61.6154"
				y1="393.2074"
				x2="413.355"
				y2="285.5856"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_76_)"
				d="M344.2,314.3l9.4-5.4c1.3-0.7,1.3-2,0-2.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4     c-1.3,0.7-1.3,2,0,2.7l9.4,5.4C340.8,315,342.9,315,344.2,314.3z"
			></path>
			<linearGradient
				id="SVGID_77_"
				gradientUnits="userSpaceOnUse"
				x1="59.2692"
				y1="385.5392"
				x2="411.0087"
				y2="277.9174"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_77_)"
				d="M397.7,280.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-35.9,20.7c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l35.9-20.7C399,282.6,399,281.4,397.7,280.7z"
			></path>
			<linearGradient
				id="SVGID_78_"
				gradientUnits="userSpaceOnUse"
				x1="82.1439"
				y1="460.3004"
				x2="433.8834"
				y2="352.6786"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_78_)"
				d="M159.3,439l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C160.6,440.9,160.6,439.7,159.3,439z"
			></path>
			<linearGradient
				id="SVGID_79_"
				gradientUnits="userSpaceOnUse"
				x1="80.8011"
				y1="455.9119"
				x2="432.5407"
				y2="348.2901"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_79_)"
				d="M177,428.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C178.2,430.7,178.2,429.5,177,428.7z"
			></path>
			<linearGradient
				id="SVGID_80_"
				gradientUnits="userSpaceOnUse"
				x1="79.4584"
				y1="451.5235"
				x2="431.198"
				y2="343.9017"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_80_)"
				d="M194.6,418.5l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C195.9,420.5,195.9,419.3,194.6,418.5z"
			></path>
			<linearGradient
				id="SVGID_81_"
				gradientUnits="userSpaceOnUse"
				x1="78.1157"
				y1="447.135"
				x2="429.8552"
				y2="339.5132"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_81_)"
				d="M212.3,408.3l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C213.6,410.3,213.6,409.1,212.3,408.3z"
			></path>
			<linearGradient
				id="SVGID_82_"
				gradientUnits="userSpaceOnUse"
				x1="76.7729"
				y1="442.7466"
				x2="428.5125"
				y2="335.1248"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_82_)"
				d="M230,398.1l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C231.3,400.1,231.3,398.9,230,398.1z"
			></path>
			<linearGradient
				id="SVGID_83_"
				gradientUnits="userSpaceOnUse"
				x1="67.3738"
				y1="412.0274"
				x2="419.1133"
				y2="304.4055"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_83_)"
				d="M353.8,326.7l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C355.1,328.6,355.1,327.4,353.8,326.7z"
			></path>
			<linearGradient
				id="SVGID_84_"
				gradientUnits="userSpaceOnUse"
				x1="72.7447"
				y1="429.5812"
				x2="424.4843"
				y2="321.9594"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_84_)"
				d="M318.4,347.1l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-80.2,46.3c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l80.2-46.3C319.7,349,319.7,347.8,318.4,347.1z"
			></path>
			<linearGradient
				id="SVGID_85_"
				gradientUnits="userSpaceOnUse"
				x1="68.7165"
				y1="416.4158"
				x2="420.4561"
				y2="308.794"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_85_)"
				d="M336.1,336.9l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C337.4,338.8,337.4,337.6,336.1,336.9z"
			></path>
			<linearGradient
				id="SVGID_86_"
				gradientUnits="userSpaceOnUse"
				x1="63.973"
				y1="400.9127"
				x2="415.7126"
				y2="293.2909"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_86_)"
				d="M399.2,300.6l-9.4-5.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l9.4,5.4     c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C400.5,302.6,400.5,301.3,399.2,300.6z"
			></path>
			<linearGradient
				id="SVGID_87_"
				gradientUnits="userSpaceOnUse"
				x1="63.5894"
				y1="399.659"
				x2="415.3289"
				y2="292.0371"
			>
				<stop offset="0" style={{ stopColor: "#7A7A7A" }}></stop>
				<stop offset="1" style={{ stopColor: "#ADADAD" }}></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_87_)"
				d="M415.7,291.1l-2.4-1.4c-1.3-0.7-3.4-0.7-4.7,0l-9.4,5.4c-1.3,0.7-1.3,2,0,2.7l2.4,1.4     c1.3,0.7,3.4,0.7,4.7,0l9.4-5.4C417,293,417,291.8,415.7,291.1z"
			></path>
			<g
				id="laptopGlass1"
				data-svg-origin="84.9000015258789 53.400001525878906"
				style={{ transformOrigin: "0px 0px" }}
				transform="matrix(1,0,0,1,0,0)"
			>
				<linearGradient
					id="SVGID_88_"
					gradientUnits="userSpaceOnUse"
					x1="42.4537"
					y1="319.5798"
					x2="373.8616"
					y2="128.2413"
				>
					<stop offset="0" id="gc3"></stop>
					<stop offset="1" id="gc2"></stop>
				</linearGradient>
				<polygon
					opacity="0.7"
					fill="url(#SVGID_88_)"
					points="86.4,192 330.6,53.4 330.6,255.4 86.4,395.6 "
				></polygon>
				<g className="border" opacity="0.3" style={{ opacity: "0.3" }}>
					<path
						fill="#FFFFFF"
						d="M86.4,395.6L86,370.2l-0.3-25.5c-0.2-17-0.3-33.9-0.4-50.9l-0.2-25.5c-0.1-8.5-0.1-17-0.1-25.5L84.9,192   l0-0.8l0.7-0.4l30.5-17.4l30.6-17.3l61.1-34.5l61.3-34.2c10.2-5.7,20.5-11.3,30.7-17c10.2-5.7,20.5-11.3,30.8-16.9   c-10.1,5.9-20.2,11.8-30.3,17.7C290.2,77,280.1,82.9,270,88.7l-60.8,35.1l-61,34.8L117.6,176l-30.6,17.3l0.7-1.2l-0.1,50.9   c0,8.5,0,17-0.1,25.5l-0.2,25.5c-0.1,17-0.2,33.9-0.4,50.9l-0.3,25.5L86.4,395.6z"
					></path>
				</g>
			</g>
			<g
				id="laptopGlass2"
				data-svg-origin="134.59999084472656 84.40003204345703"
				style={{ transformOrigin: "0px 0px" }}
				transform="matrix(1,0,0,1,0,0)"
			>
				<linearGradient
					id="SVGID_89_"
					gradientUnits="userSpaceOnUse"
					x1="92.0753"
					y1="350.6635"
					x2="423.4832"
					y2="159.325"
				>
					<stop offset="0" id="gc4"></stop>
					<stop offset="1" id="gc3"></stop>
				</linearGradient>
				<polygon
					opacity="0.7"
					fill="url(#SVGID_89_)"
					points="136,223.1 380.3,84.5 380.3,286.5 136,426.7 "
				></polygon>

				<g className="border" opacity="0.3" style={{ opacity: "0.3" }}>
					<path
						fill="#FFFFFF"
						d="M136,426.7l-0.3-25.5l-0.3-25.5c-0.2-17-0.3-33.9-0.4-50.9l-0.2-25.5c-0.1-8.5-0.1-17-0.1-25.5l-0.1-50.9   l0-0.8l0.7-0.4l30.5-17.4l30.6-17.3l61.1-34.5l61.3-34.2c10.2-5.7,20.5-11.3,30.7-17c10.2-5.7,20.5-11.3,30.8-16.9   c-10.1,5.9-20.2,11.8-30.3,17.7c-10.1,5.9-20.2,11.8-30.4,17.6l-60.8,35.1l-61,34.8L167.2,207l-30.6,17.3l0.7-1.2l-0.1,50.9   c0,8.5,0,17-0.1,25.5l-0.2,25.5c-0.1,17-0.2,33.9-0.4,50.9l-0.3,25.5L136,426.7z"
					></path>
				</g>
			</g>
		</StyledSVG>
	);
};

export default Customs;
