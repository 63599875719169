import { CSSTransition, TransitionGroup } from "react-transition-group";
import { loaderDelay, navDelay } from "../../utils/index";
import styled from "styled-components";
import mixins from '../../styles/mixins'
import Background from "../Background";
import { useEffect, useState } from "react";
import usePrefersReducedMotion from "../../hooks/usePrefersReducedMotion";

const StyledHeroSection = styled.section`
${mixins.flexCenter[0]}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  height: 100vh;
  padding: 0;

  @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
    height: auto;
    padding-top: var(--nav-height);
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }
  h2, h3 {
    font-family: var(--font-orbit);
    font-weight: 900;
    text-transform: uppercase;
    user-select: none;
  }

  h3 {
    margin-top: 5px;
    color: var(--slate);
    line-height: 0.9;
    font-size: clamp(32px, 7vw, 72px)
  }

  p {
    padding: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    margin-top: 50px;
    ${mixins.bigButton[0]};
    ${mixins.glowEffect[0]};
    position: relative;
  }

  @keyframes glow-visibility {
    0%, 100% {opacity: 0}
    25%, 75% {opacity: 1}
  }
`;

const Hero = () => {
    // const [dark, setDark] = useState(
	// 	window.matchMedia("(prefers-color-scheme: dark)").matches
	// );
  const [isMounted, setIsMounted] = useState(false)
  const prefersReducedMotion = usePrefersReducedMotion()

  useEffect(() => {
    if (prefersReducedMotion) return

    const timeout = setTimeout(() => setIsMounted(true), navDelay)
    return () => clearTimeout(timeout)
  },[prefersReducedMotion])

	const one = <h1>Hi,</h1>;
	const two = <h2 className="big-heading">I'm Thomas,</h2>;
	const three = <h3 className="big-heading">Software Engineer.</h3>;
	const four = (
		<>
			<p>I'm a full-stack software engineer with education and experience in marketing and economics.
			</p>
		</>
	);
	const five = (
		<a
			className="email-link"
			href="mailto:thomas.lawlor96@gmail.com"
			target="_blank"
			rel="noreferrer"
		>
			Send me a message!
      <svg className="glow-container">
        <rect className="glow-blur" pathLength='100' strokeLinecap="round"></rect>
        <rect className="glow-line" pathLength='100' strokeLinecap="round"></rect>
      </svg>
		</a>
	);

	const items = [one, two, three, four, five];

	return (
		<StyledHeroSection>
      <Background />
			<TransitionGroup component={null}>
				{isMounted && 
          items.map((item, i) => (
					  <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
						  <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
					  </CSSTransition>
				))}
			</TransitionGroup>
		</StyledHeroSection>
	);
};

export default Hero;
