import { CSSTransition, TransitionGroup } from "react-transition-group";
import { loaderDelay, navDelay } from "../utils";
import styled from "styled-components";
import usePrefersReducedMotion from "../hooks/usePrefersReducedMotion";
import { useEffect, useState } from "react";

const StyledBackgroundDiv = styled.div`
	--orbitMercury: 7.1867343561s;
	--orbitVenus: 18.4555338265s;
	--orbitEarth: 30s;
	--orbitMoon: 2.2440352158s;
	--orbitMars: 56.4261314589s;
	--orbitJupiter: 355.7228171013s;
	--orbitSaturn: 882.6952471456s;
	--orbitUranus: 2512.4001967933s;
	--orbitNeptune: 4911.7838624549s;
	--orbitPluto: 7439.7074054575s;
	--orbitAsteroids: 179.9558282773s;

    height: 80vh;
    width: 100vw;
    position: absolute;
    z-index: -99999;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    top: 2.5%;
    left: 19%;

    .SolarSystem {
        margin: 0 auto;
        width: 100%;
        height: 100%;
        position: relative;

        div {
            border-radius: 1000px;
            top: 50%;
            left: 50%;
            position: absolute;
        }
        div:not(.sun) {
            border: 1px solid rgba(102, 166, 229, 0.12);

            &::before {
                left: 50%;
                border-radius: 100px;
                content: "";
                position: absolute;
            }
        }
        div:not(.asteroid-belt)::before {
            box-shadow: inset 0 6px 0 -2px rgba(0, 0, 0, 0.25);
        }
        .sun {
            background: radial-gradient(
                ellipse at center,
                #ffd000 1%,
                #f9b700 39%,
                #f9b700 39%,
                #e06317 100%
            );
            height: 40px;
            width: 40px;
            margin-top: -20px;
            margin-left: -20px;
            background-clip: padding-box;
            border: 0 !important;
            background-position: -28px -103px;
            background-size: 175%;
            box-shadow: 0 0 10px 2px rgba(255, 107, 0, 0.4),
                0 0 22px 11px rgba(255, 203, 0, 0.13);
        }
        .mercury {
            height: 70px;
            width: 70px;
            margin-top: -35px;
            margin-left: -35px;
            -webkit-animation: orb 7.1867343561s linear infinite;
            animation: orb 7.1867343561s linear infinite;

            &::before {
                height: 4px;
                width: 4px;
                background: #9f5e26;
                margin-top: -2px;
                margin-left: -2px;
            }
        }
        .venus {
            height: 100px;
            width: 100px;
            margin-top: -50px;
            margin-left: -50px;
            -webkit-animation: orb 18.4555338265s linear infinite;
            animation: orb 18.4555338265s linear infinite;

            &::before {
                height: 8px;
                width: 8px;
                background: #beb768;
                margin-top: -4px;
                margin-left: -4px;
            }
        }
        .earth {
            height: 145px;
            width: 145px;
            margin-top: -72.5px;
            margin-left: -72.5px;
            -webkit-animation: orb 30s linear infinite;
            animation: orb 30s linear infinite;

            &::before {
                height: 6px;
                width: 6px;
                background: #11abe9;
                margin-top: -3px;
                margin-left: -3px;
            }
            &::after {
                position: absolute;
                content: "";
                height: 18px;
                width: 18px;
                left: 50%;
                top: 0px;
                margin-left: -9px;
                margin-top: -9px;
                border-radius: 100px;
                box-shadow: 0 -10px 0 -8px grey;
                -webkit-animation: orb 2.2440352158s linear infinite;
                animation: orb 2.2440352158s linear infinite;
            }
        }
        .mars {
            height: 190px;
            width: 190px;
            margin-top: -95px;
            margin-left: -95px;
            -webkit-animation: orb 56.4261314589s linear infinite;
            animation: orb 56.4261314589s linear infinite;

            &::before {
                height: 6px;
                width: 6px;
                background: #cf3921;
                margin-top: -3px;
                margin-left: -3px;
            }
        }
        .jupiter {
            height: 340px;
            width: 340px;
            margin-top: -170px;
            margin-left: -170px;
            -webkit-animation: orb 355.7228171013s linear infinite;
            animation: orb 355.7228171013s linear infinite;
            
            &::before {
                height: 18px;
                width: 18px;
                background: #c76e2a;
                margin-top: -9px;
                margin-left: -9px;
            }
        }
        .saturn {
            height: 440px;
            width: 440px;
            margin-top: -220px;
            margin-left: -220px;
            -webkit-animation: orb 882.6952471456s linear infinite;
            animation: orb 882.6952471456s linear infinite;
            
            &::before {
                height: 12px;
                width: 12px;
                background: #e7c194;
                margin-top: -6px;
                margin-left: -6px;
            }
            &::after {
                position: absolute;
                content: "";
                height: 2.34%;
                width: 4.676%;
                left: 50%;
                top: 0px;
                transform: rotateZ(-52deg);
                margin-left: -2.3%;
                margin-top: -1.2%;
                border-radius: 50% 50% 50% 50%;
                box-shadow: 0 1px 0 1px #987641, 3px 1px 0 #987641, -3px 1px 0 #987641;
                -webkit-animation: orb 882.6952471456s linear infinite;
                animation: orb 882.6952471456s linear infinite;
                animation-direction: reverse;
                transform-origin: 52% 60%;
            }
        }
        .uranus {
            height: 520px;
            width: 520px;
            margin-top: -260px;
            margin-left: -260px;
            -webkit-animation: orb 2512.4001967933s linear infinite;
            animation: orb 2512.4001967933s linear infinite;
            
            &::before {
                height: 10px;
                width: 10px;
                background: #b5e3e3;
                margin-top: -5px;
                margin-left: -5px;
            }
        }
        .neptune {
            height: 630px;
            width: 630px;
            margin-top: -315px;
            margin-left: -315px;
            -webkit-animation: orb 4911.7838624549s linear infinite;
            animation: orb 4911.7838624549s linear infinite;
            
            &::before {
                height: 10px;
                width: 10px;
                background: #175e9e;
                margin-top: -5px;
                margin-left: -5px;
            }
        }
        .asteroid-belt {
            opacity: 0.7;
            border-color: transparent !important;
            height: 300px;
            width: 300px;
            margin-top: -150px;
            margin-left: -150px;
            -webkit-animation: orb 179.9558282773s linear infinite;
            animation: orb 179.9558282773s linear infinite;
            overflow: hidden;

            &::before {
                top: 50%;
                height: 210px;
                width: 210px;
                margin-left: -105px;
                margin-top: -105px;
                background: transparent;
                border-radius: 140px !important;
                box-shadow: 142px 14px 0 -104px rgba(255, 255, 255, 0.059),
                    -99px -81px 0 -104px rgba(255, 255, 255, 0.843),
                    -100px -96px 0 -104px rgba(255, 255, 255, 0.589),
                    -17px -11px 0 -104px rgba(255, 255, 255, 0.733),
                    -141px 48px 0 -104px rgba(255, 255, 255, 0.843),
                    61px 103px 0 -104px rgba(255, 255, 255, 0.15),
                    140px -107px 0 -104px rgba(255, 255, 255, 0.474),
                    61px -130px 0 -104px rgba(255, 255, 255, 0.399),
                    -7px 5px 0 -104px rgba(255, 255, 255, 0.978),
                    -68px 16px 0 -104px rgba(255, 255, 255, 0.921),
                    1px -103px 0 -104px rgba(255, 255, 255, 0.514),
                    100px -41px 0 -104px rgba(255, 255, 255, 0.19),
                    131px -122px 0 -104px rgba(255, 255, 255, 0.692),
                    22px -110px 0 -104px rgba(255, 255, 255, 0.702),
                    109px 73px 0 -104px rgba(255, 255, 255, 0.84),
                    111px -5px 0 -104px rgba(255, 255, 255, 0.98),
                    -121px 97px 0 -104px rgba(255, 255, 255, 0.889),
                    7px 77px 0 -104px rgba(255, 255, 255, 0.498),
                    -142px 5px 0 -104px rgba(255, 255, 255, 0.4),
                    19px -34px 0 -104px rgba(255, 255, 255, 0.155),
                    78px -47px 0 -104px rgba(255, 255, 255, 0.092),
                    26px -8px 0 -104px rgba(255, 255, 255, 0.428),
                    92px -85px 0 -104px rgba(255, 255, 255, 0.618),
                    124px 109px 0 -104px rgba(255, 255, 255, 0.829),
                    127px -116px 0 -104px rgba(255, 255, 255, 0.286),
                    -10px 132px 0 -104px rgba(255, 255, 255, 0.779),
                    85px -6px 0 -104px rgba(255, 255, 255, 0.335),
                    66px 64px 0 -104px rgba(255, 255, 255, 0.514),
                    -129px -119px 0 -104px rgba(255, 255, 255, 0.148),
                    39px -97px 0 -104px rgba(255, 255, 255, 0.089),
                    -129px -62px 0 -104px rgba(255, 255, 255, 0.173),
                    -32px 59px 0 -104px rgba(255, 255, 255, 0.902),
                    -93px -143px 0 -104px rgba(255, 255, 255, 0.922),
                    -16px -28px 0 -104px rgba(255, 255, 255, 0.439),
                    32px -7px 0 -104px rgba(255, 255, 255, 0.959),
                    -117px 68px 0 -104px rgba(255, 255, 255, 0.238),
                    -86px -30px 0 -104px rgba(255, 255, 255, 0.944),
                    -5px -37px 0 -104px rgba(255, 255, 255, 0.93),
                    -100px 59px 0 -104px rgba(255, 255, 255, 0.105),
                    40px -92px 0 -104px rgba(255, 255, 255, 0.055),
                    -128px -84px 0 -104px rgba(255, 255, 255, 0.521),
                    -75px 84px 0 -104px rgba(255, 255, 255, 0.564),
                    -136px 128px 0 -104px rgba(255, 255, 255, 0.992),
                    -80px -132px 0 -104px rgba(255, 255, 255, 0.378),
                    2px 91px 0 -104px rgba(255, 255, 255, 0.409),
                    -15px -99px 0 -104px rgba(255, 255, 255, 0.537),
                    -127px 132px 0 -104px rgba(255, 255, 255, 0.744),
                    108px 102px 0 -104px rgba(255, 255, 255, 0.571),
                    93px 141px 0 -104px rgba(255, 255, 255, 0.986),
                    -142px 114px 0 -104px rgba(255, 255, 255, 0.164),
                    49px -89px 0 -104px rgba(255, 255, 255, 0.95),
                    -68px -88px 0 -104px rgba(255, 255, 255, 0.344),
                    67px -68px 0 -104px rgba(255, 255, 255, 0.401),
                    -15px -101px 0 -104px rgba(255, 255, 255, 0.954),
                    61px 110px 0 -104px rgba(255, 255, 255, 0.715),
                    50px 38px 0 -104px rgba(255, 255, 255, 0.422),
                    126px -44px 0 -104px rgba(255, 255, 255, 0.457),
                    40px 103px 0 -104px rgba(255, 255, 255, 0.929),
                    -88px -11px 0 -104px rgba(255, 255, 255, 0.335),
                    45px -79px 0 -104px rgba(255, 255, 255, 0.651),
                    125px -35px 0 -104px rgba(255, 255, 255, 0.398),
                    -10px -43px 0 -104px rgba(255, 255, 255, 0.103),
                    127px -104px 0 -104px rgba(255, 255, 255, 0.493),
                    13px 85px 0 -104px rgba(255, 255, 255, 0.836),
                    -59px 55px 0 -104px rgba(255, 255, 255, 0.592),
                    82px 134px 0 -104px rgba(255, 255, 255, 0.245),
                    28px 93px 0 -104px rgba(255, 255, 255, 0.954),
                    -100px 83px 0 -104px rgba(255, 255, 255, 0.74),
                    45px -43px 0 -104px rgba(255, 255, 255, 0.085),
                    105px -119px 0 -104px rgba(255, 255, 255, 0.751),
                    41px -5px 0 -104px rgba(255, 255, 255, 0.83),
                    8px -118px 0 -104px rgba(255, 255, 255, 0.331),
                    69px 73px 0 -104px rgba(255, 255, 255, 0.127),
                    -45px 6px 0 -104px rgba(255, 255, 255, 0.82),
                    -48px 45px 0 -104px rgba(255, 255, 255, 0.171),
                    89px -126px 0 -104px rgba(255, 255, 255, 0.323),
                    137px -16px 0 -104px rgba(255, 255, 255, 0.484),
                    137px -100px 0 -104px rgba(255, 255, 255, 0.357),
                    -52px 79px 0 -104px rgba(255, 255, 255, 0.447),
                    -46px 33px 0 -104px rgba(255, 255, 255, 0.816),
                    -7px -127px 0 -104px rgba(255, 255, 255, 0.757),
                    31px 140px 0 -104px rgba(255, 255, 255, 0.896),
                    -15px 43px 0 -104px rgba(255, 255, 255, 0.749),
                    108px -98px 0 -104px rgba(255, 255, 255, 0.381),
                    104px -123px 0 -104px rgba(255, 255, 255, 0.828),
                    21px 116px 0 -104px rgba(255, 255, 255, 0.284),
                    -101px 50px 0 -104px rgba(255, 255, 255, 0.461),
                    29px -103px 0 -104px rgba(255, 255, 255, 0.778),
                    113px 114px 0 -104px rgba(255, 255, 255, 0.751),
                    16px -30px 0 -104px rgba(255, 255, 255, 0.964),
                    81px 132px 0 -104px rgba(255, 255, 255, 0.176),
                    131px -32px 0 -104px rgba(255, 255, 255, 0.426),
                    112px 109px 0 -104px rgba(255, 255, 255, 0.124),
                    40px -65px 0 -104px rgba(255, 255, 255, 0.982),
                    57px -64px 0 -104px rgba(255, 255, 255, 0.23),
                    135px -91px 0 -104px rgba(255, 255, 255, 0.711),
                    137px 34px 0 -104px rgba(255, 255, 255, 0.779),
                    83px 117px 0 -104px rgba(255, 255, 255, 0.732),
                    -37px -18px 0 -104px rgba(255, 255, 255, 0.562),
                    120px -7px 0 -104px rgba(255, 255, 255, 0.222),
                    120px -3px 0 -104px rgba(255, 255, 255, 0.065),
                    22px -113px 0 -104px rgba(255, 255, 255, 0.742),
                    59px 91px 0 -104px rgba(255, 255, 255, 0.473),
                    72px 20px 0 -104px rgba(255, 255, 255, 0.682),
                    70px -16px 0 -104px rgba(255, 255, 255, 0.705),
                    -128px 93px 0 -104px rgba(255, 255, 255, 0.318),
                    31px 90px 0 -104px rgba(255, 255, 255, 0.634),
                    -137px 143px 0 -104px rgba(255, 255, 255, 0.843),
                    -97px -43px 0 -104px rgba(255, 255, 255, 0.73),
                    -94px 110px 0 -104px rgba(255, 255, 255, 0.394),
                    -44px 73px 0 -104px rgba(255, 255, 255, 0.989),
                    18px -47px 0 -104px rgba(255, 255, 255, 0.989),
                    -118px -27px 0 -104px rgba(255, 255, 255, 0.043),
                    -33px 16px 0 -104px rgba(255, 255, 255, 0.355),
                    122px 29px 0 -104px rgba(255, 255, 255, 0.027),
                    32px 103px 0 -104px rgba(255, 255, 255, 0.957),
                    69px -59px 0 -104px rgba(255, 255, 255, 0.777),
                    80px -108px 0 -104px rgba(255, 255, 255, 0.538),
                    6px -55px 0 -104px rgba(255, 255, 255, 0.642),
                    133px 12px 0 -104px rgba(255, 255, 255, 0.772),
                    52px -133px 0 -104px rgba(255, 255, 255, 0.033),
                    -107px -46px 0 -104px rgba(255, 255, 255, 0.12),
                    39px 45px 0 -104px rgba(255, 255, 255, 0.58),
                    141px -30px 0 -104px rgba(255, 255, 255, 0.38),
                    114px -18px 0 -104px rgba(255, 255, 255, 0.351),
                    -39px 89px 0 -104px rgba(255, 255, 255, 0.264),
                    23px 18px 0 -104px rgba(255, 255, 255, 0.722),
                    10px 18px 0 -104px rgba(255, 255, 255, 0.036),
                    -84px -17px 0 -104px rgba(255, 255, 255, 0.034),
                    110px -63px 0 -104px rgba(255, 255, 255, 0.886),
                    -27px 108px 0 -104px rgba(255, 255, 255, 0.994),
                    101px 31px 0 -104px rgba(255, 255, 255, 0.646),
                    109px -24px 0 -104px rgba(255, 255, 255, 0.147),
                    54px -85px 0 -104px rgba(255, 255, 255, 0.442),
                    -104px -118px 0 -104px rgba(255, 255, 255, 0.044),
                    -143px -92px 0 -104px rgba(255, 255, 255, 0.86),
                    76px 108px 0 -104px rgba(255, 255, 255, 0.533),
                    21px -113px 0 -104px rgba(255, 255, 255, 0.052),
                    -49px -115px 0 -104px rgba(255, 255, 255, 0.743),
                    -116px -40px 0 -104px rgba(255, 255, 255, 0.971),
                    -26px -115px 0 -104px rgba(255, 255, 255, 0.202),
                    -132px -119px 0 -104px rgba(255, 255, 255, 0.181),
                    -129px 39px 0 -104px rgba(255, 255, 255, 0.629),
                    37px -26px 0 -104px rgba(255, 255, 255, 0.881),
                    -9px 143px 0 -104px rgba(255, 255, 255, 0.587),
                    -131px 77px 0 -104px rgba(255, 255, 255, 0.375),
                    -62px -49px 0 -104px rgba(255, 255, 255, 0.462),
                    -19px -85px 0 -104px rgba(255, 255, 255, 0.609),
                    84px 50px 0 -104px rgba(255, 255, 255, 0.765),
                    98px 119px 0 -104px rgba(255, 255, 255, 0.412),
                    48px 144px 0 -104px rgba(255, 255, 255, 0.717),
                    -39px -7px 0 -104px rgba(255, 255, 255, 0.051),
                    120px 133px 0 -104px rgba(255, 255, 255, 0.044),
                    23px 122px 0 -104px rgba(255, 255, 255, 0.794),
                    -141px 69px 0 -104px rgba(255, 255, 255, 0.167),
                    -88px 95px 0 -104px rgba(255, 255, 255, 0.396),
                    30px 33px 0 -104px rgba(255, 255, 255, 0.99),
                    126px -137px 0 -104px rgba(255, 255, 255, 0.532),
                    20px 7px 0 -104px rgba(255, 255, 255, 0.705),
                    -4px -90px 0 -104px rgba(255, 255, 255, 0.447),
                    50px 82px 0 -104px rgba(255, 255, 255, 0.713),
                    107px 139px 0 -104px rgba(255, 255, 255, 0.247),
                    -126px -35px 0 -104px rgba(255, 255, 255, 0.357),
                    -43px 103px 0 -104px rgba(255, 255, 255, 0.731),
                    23px -7px 0 -104px rgba(255, 255, 255, 0.065),
                    81px -13px 0 -104px rgba(255, 255, 255, 0.414),
                    76px -98px 0 -104px rgba(255, 255, 255, 0.555),
                    63px -1px 0 -104px rgba(255, 255, 255, 0.369),
                    134px 58px 0 -104px rgba(255, 255, 255, 0.166),
                    -28px -44px 0 -104px rgba(255, 255, 255, 0.784),
                    -23px 49px 0 -104px rgba(255, 255, 255, 0.198),
                    -119px -7px 0 -104px rgba(255, 255, 255, 0.726),
                    -101px 80px 0 -104px rgba(255, 255, 255, 0.884),
                    48px -39px 0 -104px rgba(255, 255, 255, 0.881),
                    -10px -67px 0 -104px rgba(255, 255, 255, 0.334),
                    -136px -85px 0 -104px rgba(255, 255, 255, 0.38),
                    -102px 79px 0 -104px rgba(255, 255, 255, 0.152),
                    125px 15px 0 -104px rgba(255, 255, 255, 0.634),
                    -56px -86px 0 -104px rgba(255, 255, 255, 0.788),
                    -134px -46px 0 -104px rgba(255, 255, 255, 0.037),
                    -1px 83px 0 -104px rgba(255, 255, 255, 0.158),
                    3px -25px 0 -104px rgba(255, 255, 255, 0.464),
                    -103px -26px 0 -104px rgba(255, 255, 255, 0.319),
                    107px 81px 0 -104px rgba(255, 255, 255, 0.737),
                    -97px 12px 0 -104px rgba(255, 255, 255, 0.086),
                    -2px -56px 0 -104px rgba(255, 255, 255, 0.13),
                    17px -108px 0 -104px rgba(255, 255, 255, 0.896),
                    -37px -79px 0 -104px rgba(255, 255, 255, 0.716),
                    96px -78px 0 -104px rgba(255, 255, 255, 0.928),
                    109px -21px 0 -104px rgba(255, 255, 255, 0.182),
                    48px 14px 0 -104px rgba(255, 255, 255, 0.007),
                    87px -136px 0 -104px rgba(255, 255, 255, 0.922),
                    16px 93px 0 -104px rgba(255, 255, 255, 0.595),
                    11px 5px 0 -104px rgba(255, 255, 255, 0.134),
                    129px -112px 0 -104px rgba(255, 255, 255, 0.373),
                    -99px -139px 0 -104px rgba(255, 255, 255, 0.68),
                    62px -83px 0 -104px rgba(255, 255, 255, 0.924),
                    84px 139px 0 -104px rgba(255, 255, 255, 0.528),
                    -92px 21px 0 -104px rgba(255, 255, 255, 0.853),
                    -86px -75px 0 -104px rgba(255, 255, 255, 0.75),
                    52px 39px 0 -104px rgba(255, 255, 255, 0.939),
                    -59px -88px 0 -104px rgba(255, 255, 255, 0.421),
                    96px -34px 0 -104px rgba(255, 255, 255, 0.979),
                    -39px -26px 0 -104px rgba(255, 255, 255, 0.324),
                    -114px -28px 0 -104px rgba(255, 255, 255, 0.928),
                    55px 88px 0 -104px rgba(255, 255, 255, 0.591),
                    143px 14px 0 -104px rgba(255, 255, 255, 0.063),
                    2px 23px 0 -104px rgba(255, 255, 255, 0.612),
                    65px 20px 0 -104px rgba(255, 255, 255, 0.042),
                    -126px 5px 0 -104px rgba(255, 255, 255, 0.715),
                    69px -131px 0 -104px rgba(255, 255, 255, 0.479),
                    91px -41px 0 -104px rgba(255, 255, 255, 0.676),
                    136px 117px 0 -104px rgba(255, 255, 255, 0.042),
                    -91px -34px 0 -104px rgba(255, 255, 255, 0.366),
                    106px 19px 0 -104px rgba(255, 255, 255, 0.429),
                    -89px 85px 0 -104px rgba(255, 255, 255, 0.013),
                    -132px -89px 0 -104px rgba(255, 255, 255, 0.285),
                    39px 36px 0 -104px rgba(255, 255, 255, 0.3),
                    15px 25px 0 -104px rgba(255, 255, 255, 0.514),
                    2px -142px 0 -104px rgba(255, 255, 255, 0.033),
                    -125px -28px 0 -104px rgba(255, 255, 255, 0.229),
                    96px -1px 0 -104px rgba(255, 255, 255, 0.554),
                    64px 25px 0 -104px rgba(255, 255, 255, 0.704),
                    -44px 115px 0 -104px rgba(255, 255, 255, 0.365),
                    -110px -77px 0 -104px rgba(255, 255, 255, 0.62),
                    -28px 86px 0 -104px rgba(255, 255, 255, 0.923),
                    96px 79px 0 -104px rgba(255, 255, 255, 0.144),
                    -21px 6px 0 -104px rgba(255, 255, 255, 0.078),
                    -119px -107px 0 -104px rgba(255, 255, 255, 0.282),
                    135px -3px 0 -104px rgba(255, 255, 255, 0.506),
                    -46px -4px 0 -104px rgba(255, 255, 255, 0.389),
                    64px 139px 0 -104px rgba(255, 255, 255, 0.554),
                    -98px -2px 0 -104px rgba(255, 255, 255, 0.988),
                    -126px -94px 0 -104px rgba(255, 255, 255, 0.237),
                    -78px -128px 0 -104px rgba(255, 255, 255, 0.624),
                    -123px -33px 0 -104px rgba(255, 255, 255, 0.987),
                    -124px 129px 0 -104px rgba(255, 255, 255, 0.732),
                    25px 27px 0 -104px rgba(255, 255, 255, 1),
                    -25px -118px 0 -104px rgba(255, 255, 255, 0.486),
                    117px 21px 0 -104px rgba(255, 255, 255, 0.831),
                    25px -132px 0 -104px rgba(255, 255, 255, 0.352),
                    29px -105px 0 -104px rgba(255, 255, 255, 0.942),
                    -40px 69px 0 -104px rgba(255, 255, 255, 0.567),
                    89px -123px 0 -104px rgba(255, 255, 255, 0.4),
                    30px 84px 0 -104px rgba(255, 255, 255, 0.501),
                    30px -140px 0 -104px rgba(255, 255, 255, 0.922),
                    -19px -103px 0 -104px rgba(255, 255, 255, 0.379),
                    74px -71px 0 -104px rgba(255, 255, 255, 0.454),
                    132px -27px 0 -104px rgba(255, 255, 255, 0.845),
                    -20px -53px 0 -104px rgba(255, 255, 255, 0.44),
                    126px -135px 0 -104px rgba(255, 255, 255, 0.653),
                    77px -129px 0 -104px rgba(255, 255, 255, 0.279),
                    73px 90px 0 -104px rgba(255, 255, 255, 0.383),
                    -35px -141px 0 -104px rgba(255, 255, 255, 0.417),
                    -32px 103px 0 -104px rgba(255, 255, 255, 0.724),
                    24px 80px 0 -104px rgba(255, 255, 255, 0.426),
                    -142px 96px 0 -104px rgba(255, 255, 255, 0.316),
                    63px 53px 0 -104px rgba(255, 255, 255, 0.582),
                    -28px 18px 0 -104px rgba(255, 255, 255, 0.176),
                    -55px 96px 0 -104px rgba(255, 255, 255, 0.155),
                    -32px -123px 0 -104px rgba(255, 255, 255, 0.607),
                    -68px 119px 0 -104px rgba(255, 255, 255, 0.037),
                    98px -143px 0 -104px rgba(255, 255, 255, 0.016),
                    12px 97px 0 -104px rgba(255, 255, 255, 0.948),
                    -107px 105px 0 -104px rgba(255, 255, 255, 0.968),
                    65px -60px 0 -104px rgba(255, 255, 255, 0.786),
                    -2px 87px 0 -104px rgba(255, 255, 255, 0.231),
                    -17px 94px 0 -104px rgba(255, 255, 255, 0.427),
                    108px 99px 0 -104px rgba(255, 255, 255, 0.023),
                    -135px -76px 0 -104px rgba(255, 255, 255, 0.016),
                    136px -92px 0 -104px rgba(255, 255, 255, 0.394),
                    82px -129px 0 -104px rgba(255, 255, 255, 0.576),
                    -11px 75px 0 -104px rgba(255, 255, 255, 0.537),
                    37px 3px 0 -104px rgba(255, 255, 255, 0.269),
                    21px -5px 0 -104px rgba(255, 255, 255, 0.34),
                    64px -8px 0 -104px rgba(255, 255, 255, 0.925),
                    94px 79px 0 -104px rgba(255, 255, 255, 0.968),
                    -56px -88px 0 -104px rgba(255, 255, 255, 0.398),
                    -46px 25px 0 -104px rgba(255, 255, 255, 0.305),
                    -44px 65px 0 -104px rgba(255, 255, 255, 0.45),
                    141px -128px 0 -104px rgba(255, 255, 255, 0.699),
                    131px 23px 0 -104px rgba(255, 255, 255, 0.872),
                    129px 100px 0 -104px rgba(255, 255, 255, 0.304),
                    83px 103px 0 -104px rgba(255, 255, 255, 0.391),
                    107px -13px 0 -104px rgba(255, 255, 255, 0.742),
                    -142px -136px 0 -104px rgba(255, 255, 255, 0.826),
                    117px 66px 0 -104px rgba(255, 255, 255, 0.679),
                    99px 79px 0 -104px rgba(255, 255, 255, 0.696),
                    30px 24px 0 -104px rgba(255, 255, 255, 0.807),
                    54px -35px 0 -104px rgba(255, 255, 255, 0.269),
                    -47px -28px 0 -104px rgba(255, 255, 255, 0.273),
                    84px 118px 0 -104px rgba(255, 255, 255, 0.068),
                    69px 130px 0 -104px rgba(255, 255, 255, 0.448),
                    -82px -74px 0 -104px rgba(255, 255, 255, 0.867),
                    -115px 140px 0 -104px rgba(255, 255, 255, 0.561),
                    -15px 14px 0 -104px rgba(255, 255, 255, 0.944),
                    -52px 97px 0 -104px rgba(255, 255, 255, 0.012),
                    -89px 69px 0 -104px rgba(255, 255, 255, 0.318),
                    -135px -10px 0 -104px rgba(255, 255, 255, 0.229),
                    -70px 59px 0 -104px rgba(255, 255, 255, 0.383),
                    30px 8px 0 -104px rgba(255, 255, 255, 0.881),
                    63px 135px 0 -104px rgba(255, 255, 255, 0.473),
                    -30px 2px 0 -104px rgba(255, 255, 255, 0.477),
                    -15px -13px 0 -104px rgba(255, 255, 255, 0.873),
                    93px 130px 0 -104px rgba(255, 255, 255, 0.852),
                    13px 74px 0 -104px rgba(255, 255, 255, 0.176),
                    -128px 34px 0 -104px rgba(255, 255, 255, 0.303),
                    124px 129px 0 -104px rgba(255, 255, 255, 0.868),
                    -112px 89px 0 -104px rgba(255, 255, 255, 0.249),
                    -112px 33px 0 -104px rgba(255, 255, 255, 0.874),
                    -48px -109px 0 -104px rgba(255, 255, 255, 0.086),
                    87px 32px 0 -104px rgba(255, 255, 255, 0.227),
                    -26px -41px 0 -104px rgba(255, 255, 255, 0.91),
                    -106px 36px 0 -104px rgba(255, 255, 255, 0.405),
                    3px -60px 0 -104px rgba(255, 255, 255, 0.102),
                    -58px 131px 0 -104px rgba(255, 255, 255, 0.39),
                    -62px 51px 0 -104px rgba(255, 255, 255, 0.894),
                    109px 22px 0 -104px rgba(255, 255, 255, 0.956),
                    139px -10px 0 -104px rgba(255, 255, 255, 0.026),
                    -71px 94px 0 -104px rgba(255, 255, 255, 0.593),
                    -139px -10px 0 -104px rgba(255, 255, 255, 0.861),
                    -11px -109px 0 -104px rgba(255, 255, 255, 0.043),
                    50px 42px 0 -104px rgba(255, 255, 255, 0.408),
                    -115px 109px 0 -104px rgba(255, 255, 255, 0.155),
                    -107px 81px 0 -104px rgba(255, 255, 255, 0.021),
                    91px 38px 0 -104px rgba(255, 255, 255, 0.056),
                    26px -104px 0 -104px rgba(255, 255, 255, 0.325),
                    -142px -24px 0 -104px rgba(255, 255, 255, 0.973),
                    -15px 66px 0 -104px rgba(255, 255, 255, 0.215),
                    -144px -16px 0 -104px rgba(255, 255, 255, 0.005),
                    97px 145px 0 -104px rgba(255, 255, 255, 0.445),
                    27px 33px 0 -104px rgba(255, 255, 255, 0.413),
                    49px 26px 0 -104px rgba(255, 255, 255, 0.253),
                    -47px -40px 0 -104px rgba(255, 255, 255, 0.476),
                    -48px -61px 0 -104px rgba(255, 255, 255, 0.526),
                    -16px -144px 0 -104px rgba(255, 255, 255, 0.138),
                    -98px 134px 0 -104px rgba(255, 255, 255, 0.79),
                    -92px 10px 0 -104px rgba(255, 255, 255, 0.791),
                    38px 64px 0 -104px rgba(255, 255, 255, 0.286),
                    -91px 69px 0 -104px rgba(255, 255, 255, 0.124),
                    -70px -128px 0 -104px rgba(255, 255, 255, 0.286),
                    81px -122px 0 -104px rgba(255, 255, 255, 0.618),
                    -82px -68px 0 -104px rgba(255, 255, 255, 0.458),
                    -65px 133px 0 -104px rgba(255, 255, 255, 0.567),
                    -99px 140px 0 -104px rgba(255, 255, 255, 0.868),
                    -74px -139px 0 -104px rgba(255, 255, 255, 0.877),
                    -22px -61px 0 -104px rgba(255, 255, 255, 0.998),
                    128px -40px 0 -104px rgba(255, 255, 255, 0.127),
                    -89px 89px 0 -104px rgba(255, 255, 255, 0.622),
                    -98px -38px 0 -104px rgba(255, 255, 255, 0.275),
                    137px 23px 0 -104px rgba(255, 255, 255, 0.506),
                    -58px 137px 0 -104px rgba(255, 255, 255, 0.02),
                    45px -49px 0 -104px rgba(255, 255, 255, 0.268),
                    56px -19px 0 -104px rgba(255, 255, 255, 0.613),
                    -22px 87px 0 -104px rgba(255, 255, 255, 0.491),
                    42px 131px 0 -104px rgba(255, 255, 255, 0.265),
                    108px 69px 0 -104px rgba(255, 255, 255, 0.851),
                    126px -50px 0 -104px rgba(255, 255, 255, 0.798),
                    -44px -129px 0 -104px rgba(255, 255, 255, 0.935),
                    -100px -99px 0 -104px rgba(255, 255, 255, 0.393),
                    58px -119px 0 -104px rgba(255, 255, 255, 0.051),
                    10px 134px 0 -104px rgba(255, 255, 255, 0.91),
                    -103px -23px 0 -104px rgba(255, 255, 255, 0.035),
                    -67px 85px 0 -104px rgba(255, 255, 255, 0.431),
                    50px -66px 0 -104px rgba(255, 255, 255, 0.618),
                    93px -23px 0 -104px rgba(255, 255, 255, 0.735),
                    -25px -11px 0 -104px rgba(255, 255, 255, 0.637),
                    -115px 68px 0 -104px rgba(255, 255, 255, 0.642),
                    -69px 80px 0 -104px rgba(255, 255, 255, 0.328),
                    -29px -122px 0 -104px rgba(255, 255, 255, 0.686),
                    83px -35px 0 -104px rgba(255, 255, 255, 0.885),
                    -92px -22px 0 -104px rgba(255, 255, 255, 0.906),
                    56px -137px 0 -104px rgba(255, 255, 255, 0.776),
                    -109px -103px 0 -104px rgba(255, 255, 255, 0.978),
                    33px 29px 0 -104px rgba(255, 255, 255, 0.721),
                    -119px -121px 0 -104px rgba(255, 255, 255, 0.151),
                    -139px 64px 0 -104px rgba(255, 255, 255, 0.042),
                    137px 14px 0 -104px rgba(255, 255, 255, 0.764),
                    68px 40px 0 -104px rgba(255, 255, 255, 0.183),
                    3px -14px 0 -104px rgba(255, 255, 255, 0.351),
                    -136px 90px 0 -104px rgba(255, 255, 255, 0.851),
                    89px -76px 0 -104px rgba(255, 255, 255, 0.136),
                    -88px 8px 0 -104px rgba(255, 255, 255, 0.01),
                    18px 93px 0 -104px rgba(255, 255, 255, 0.233),
                    83px 2px 0 -104px rgba(255, 255, 255, 0.904),
                    -142px -132px 0 -104px rgba(255, 255, 255, 0.863),
                    6px 119px 0 -104px rgba(255, 255, 255, 0.34),
                    -30px 34px 0 -104px rgba(255, 255, 255, 0.85),
                    72px 32px 0 -104px rgba(255, 255, 255, 0.828),
                    -26px 29px 0 -104px rgba(255, 255, 255, 0.665);
            }
        }   
        .pluto {
            height: 780px;
            width: 780px;
            margin-top: -450px;
            margin-left: -320px;
            -webkit-animation: orb 7439.7074054575s linear infinite;
            animation: orb 7439.7074054575s linear infinite;
        
            &:before {
                height: 3px;
                width: 3px;
                background: #fff;
                margin-top: -1.5px;
                margin-left: -1.5px;
            }
        }

        &::after {
                content: "";
                position: absolute;
                height: 2px;
                width: 2px;
                top: -16rem;
                left: -16rem;
                background: white;
                box-shadow: 738px 83px 0 0px rgba(255, 255, 255, 0.236),
                    554px 1368px 0 0px rgba(255, 255, 255, 0.986),
                    1685px 190px 0 0px rgba(255, 255, 255, 0.606),
                    482px 1198px 0 0px rgba(255, 255, 255, 0.856),
                    1671px 143px 0 0px rgba(255, 255, 255, 0.579),
                    1429px 677px 0 0px rgba(255, 255, 255, 0.647),
                    1413px 764px 0 0px rgba(255, 255, 255, 0.541),
                    668px 1402px 0 0px rgba(255, 255, 255, 0.035),
                    1394px 301px 0 0px rgba(255, 255, 255, 0.998),
                    1592px 734px 0 0px rgba(255, 255, 255, 0.784),
                    1150px 591px 0 0px rgba(255, 255, 255, 0.165),
                    1789px 671px 0 0px rgba(255, 255, 255, 0.225),
                    1307px 770px 0 0px rgba(255, 255, 255, 0.867),
                    1698px 136px 0 0px rgba(255, 255, 255, 0.083),
                    957px 1520px 0 0px rgba(255, 255, 255, 0.329),
                    1712px 967px 0 0px rgba(255, 255, 255, 0.673),
                    466px 1475px 0 0px rgba(255, 255, 255, 0.698),
                    137px 756px 0 0px rgba(255, 255, 255, 0.328),
                    888px 910px 0 0px rgba(255, 255, 255, 0.547),
                    952px 444px 0 0px rgba(255, 255, 255, 0.704),
                    604px 622px 0 0px rgba(255, 255, 255, 0.604),
                    1155px 1263px 0 0px rgba(255, 255, 255, 0.889),
                    1583px 745px 0 0px rgba(255, 255, 255, 0.111),
                    800px 192px 0 0px rgba(255, 255, 255, 0.102),
                    894px 747px 0 0px rgba(255, 255, 255, 0.088),
                    1005px 1307px 0 0px rgba(255, 255, 255, 0.202),
                    284px 49px 0 0px rgba(255, 255, 255, 0.798),
                    955px 1577px 0 0px rgba(255, 255, 255, 0.236),
                    378px 1128px 0 0px rgba(255, 255, 255, 0.949),
                    641px 884px 0 0px rgba(255, 255, 255, 0.054),
                    5px 703px 0 0px rgba(255, 255, 255, 0.335),
                    67px 527px 0 0px rgba(255, 255, 255, 0.524),
                    1085px 62px 0 0px rgba(255, 255, 255, 0.373),
                    414px 945px 0 0px rgba(255, 255, 255, 0.232),
                    1482px 649px 0 0px rgba(255, 255, 255, 0.813),
                    499px 194px 0 0px rgba(255, 255, 255, 0.943),
                    1248px 1474px 0 0px rgba(255, 255, 255, 0.661),
                    518px 1126px 0 0px rgba(255, 255, 255, 0.62),
                    1282px 603px 0 0px rgba(255, 255, 255, 0.866),
                    1722px 966px 0 0px rgba(255, 255, 255, 0.957),
                    1437px 1504px 0 0px rgba(255, 255, 255, 0.007),
                    220px 16px 0 0px rgba(255, 255, 255, 0.304),
                    531px 604px 0 0px rgba(255, 255, 255, 0.394),
                    794px 509px 0 0px rgba(255, 255, 255, 0.895),
                    373px 1296px 0 0px rgba(255, 255, 255, 0.692),
                    1506px 444px 0 0px rgba(255, 255, 255, 0.106),
                    547px 416px 0 0px rgba(255, 255, 255, 0.174),
                    285px 1133px 0 0px rgba(255, 255, 255, 0.081),
                    203px 1424px 0 0px rgba(255, 255, 255, 0.173),
                    300px 342px 0 0px rgba(255, 255, 255, 0.847),
                    924px 1163px 0 0px rgba(255, 255, 255, 0.386),
                    272px 1632px 0 0px rgba(255, 255, 255, 0.184),
                    1303px 1410px 0 0px rgba(255, 255, 255, 0.966),
                    1385px 775px 0 0px rgba(255, 255, 255, 0.744),
                    325px 1123px 0 0px rgba(255, 255, 255, 0.872),
                    1669px 1687px 0 0px rgba(255, 255, 255, 0.561),
                    1386px 1074px 0 0px rgba(255, 255, 255, 0.435),
                    1661px 74px 0 0px rgba(255, 255, 255, 0.624),
                    1339px 1609px 0 0px rgba(255, 255, 255, 0.946),
                    1734px 803px 0 0px rgba(255, 255, 255, 0.098),
                    598px 1434px 0 0px rgba(255, 255, 255, 0.681),
                    980px 85px 0 0px rgba(255, 255, 255, 0.323),
                    1355px 852px 0 0px rgba(255, 255, 255, 0.581),
                    1375px 979px 0 0px rgba(255, 255, 255, 0.461),
                    48px 1206px 0 0px rgba(255, 255, 255, 0.413),
                    255px 118px 0 0px rgba(255, 255, 255, 0.287),
                    1723px 26px 0 0px rgba(255, 255, 255, 0.983),
                    180px 783px 0 0px rgba(255, 255, 255, 0.666),
                    327px 69px 0 0px rgba(255, 255, 255, 0.666),
                    1455px 669px 0 0px rgba(255, 255, 255, 0.06),
                    1148px 276px 0 0px rgba(255, 255, 255, 0.771),
                    40px 861px 0 0px rgba(255, 255, 255, 0.433),
                    530px 1336px 0 0px rgba(255, 255, 255, 0.225),
                    593px 1255px 0 0px rgba(255, 255, 255, 0.36),
                    1775px 415px 0 0px rgba(255, 255, 255, 0.163),
                    1097px 1175px 0 0px rgba(255, 255, 255, 0.955),
                    454px 666px 0 0px rgba(255, 255, 255, 0.91),
                    1699px 1115px 0 0px rgba(255, 255, 255, 0.22),
                    840px 486px 0 0px rgba(255, 255, 255, 0.431),
                    564px 1004px 0 0px rgba(255, 255, 255, 0.123),
                    597px 12px 0 0px rgba(255, 255, 255, 0.353),
                    1225px 562px 0 0px rgba(255, 255, 255, 0.822),
                    403px 1605px 0 0px rgba(255, 255, 255, 0.595),
                    647px 403px 0 0px rgba(255, 255, 255, 0.531),
                    1000px 61px 0 0px rgba(255, 255, 255, 0.012),
                    1140px 560px 0 0px rgba(255, 255, 255, 0.959),
                    1235px 1063px 0 0px rgba(255, 255, 255, 0.711),
                    767px 71px 0 0px rgba(255, 255, 255, 0.924),
                    183px 206px 0 0px rgba(255, 255, 255, 0.113),
                    1637px 1592px 0 0px rgba(255, 255, 255, 0.001),
                    154px 1010px 0 0px rgba(255, 255, 255, 0.644),
                    1500px 429px 0 0px rgba(255, 255, 255, 0.883),
                    423px 1726px 0 0px rgba(255, 255, 255, 0.907),
                    114px 1333px 0 0px rgba(255, 255, 255, 0.533),
                    809px 1136px 0 0px rgba(255, 255, 255, 0.921),
                    1767px 1542px 0 0px rgba(255, 255, 255, 0.615),
                    1243px 927px 0 0px rgba(255, 255, 255, 0.65),
                    904px 1325px 0 0px rgba(255, 255, 255, 0.754),
                    431px 1064px 0 0px rgba(255, 255, 255, 0.273),
                    647px 299px 0 0px rgba(255, 255, 255, 0.803),
                    388px 77px 0 0px rgba(255, 255, 255, 0.661),
                    947px 1789px 0 0px rgba(255, 255, 255, 0.774),
                    361px 737px 0 0px rgba(255, 255, 255, 0.646),
                    1180px 909px 0 0px rgba(255, 255, 255, 0.547),
                    1548px 1179px 0 0px rgba(255, 255, 255, 0.947),
                    59px 1615px 0 0px rgba(255, 255, 255, 0.997),
                    1094px 321px 0 0px rgba(255, 255, 255, 0.873),
                    1054px 19px 0 0px rgba(255, 255, 255, 0.942),
                    1673px 1791px 0 0px rgba(255, 255, 255, 0.144),
                    1463px 1127px 0 0px rgba(255, 255, 255, 0.852),
                    381px 1221px 0 0px rgba(255, 255, 255, 0.788),
                    1747px 480px 0 0px rgba(255, 255, 255, 0.849),
                    531px 175px 0 0px rgba(255, 255, 255, 0.412),
                    30px 1582px 0 0px rgba(255, 255, 255, 0.226),
                    989px 674px 0 0px rgba(255, 255, 255, 0.709),
                    1022px 1544px 0 0px rgba(255, 255, 255, 0.146),
                    620px 1180px 0 0px rgba(255, 255, 255, 0.465),
                    360px 1208px 0 0px rgba(255, 255, 255, 0.967),
                    1174px 410px 0 0px rgba(255, 255, 255, 0.202),
                    282px 1614px 0 0px rgba(255, 255, 255, 0.87),
                    183px 720px 0 0px rgba(255, 255, 255, 0.616),
                    1092px 705px 0 0px rgba(255, 255, 255, 0.652),
                    103px 119px 0 0px rgba(255, 255, 255, 0.152),
                    511px 651px 0 0px rgba(255, 255, 255, 0.654),
                    966px 1543px 0 0px rgba(255, 255, 255, 0.137),
                    85px 1051px 0 0px rgba(255, 255, 255, 0.462),
                    797px 1296px 0 0px rgba(255, 255, 255, 0.72),
                    1531px 1337px 0 0px rgba(255, 255, 255, 0.456),
                    1203px 1466px 0 0px rgba(255, 255, 255, 0.567),
                    582px 1783px 0 0px rgba(255, 255, 255, 0.091),
                    522px 1151px 0 0px rgba(255, 255, 255, 0.105),
                    995px 664px 0 0px rgba(255, 255, 255, 0.349),
                    478px 1102px 0 0px rgba(255, 255, 255, 0.088),
                    1560px 1123px 0 0px rgba(255, 255, 255, 0.623),
                    462px 1693px 0 0px rgba(255, 255, 255, 0.486),
                    1576px 455px 0 0px rgba(255, 255, 255, 0.453),
                    1610px 844px 0 0px rgba(255, 255, 255, 0.97),
                    485px 662px 0 0px rgba(255, 255, 255, 0.182),
                    1140px 505px 0 0px rgba(255, 255, 255, 0.182),
                    1072px 864px 0 0px rgba(255, 255, 255, 0.961),
                    1043px 182px 0 0px rgba(255, 255, 255, 0.998),
                    408px 794px 0 0px rgba(255, 255, 255, 0.13),
                    37px 819px 0 0px rgba(255, 255, 255, 0.863),
                    957px 1570px 0 0px rgba(255, 255, 255, 0.462),
                    1665px 766px 0 0px rgba(255, 255, 255, 0.112),
                    1084px 1469px 0 0px rgba(255, 255, 255, 0.92),
                    1546px 1066px 0 0px rgba(255, 255, 255, 0.344),
                    1341px 1544px 0 0px rgba(255, 255, 255, 0.584),
                    659px 383px 0 0px rgba(255, 255, 255, 0.866),
                    404px 1087px 0 0px rgba(255, 255, 255, 0.145),
                    1789px 745px 0 0px rgba(255, 255, 255, 0.931),
                    640px 1390px 0 0px rgba(255, 255, 255, 0.535),
                    730px 1533px 0 0px rgba(255, 255, 255, 0.353),
                    1326px 1725px 0 0px rgba(255, 255, 255, 0.699),
                    655px 285px 0 0px rgba(255, 255, 255, 0.352),
                    1162px 39px 0 0px rgba(255, 255, 255, 0.065),
                    1455px 1687px 0 0px rgba(255, 255, 255, 0.59),
                    178px 169px 0 0px rgba(255, 255, 255, 0.671),
                    1260px 647px 0 0px rgba(255, 255, 255, 0.497),
                    708px 974px 0 0px rgba(255, 255, 255, 0.188),
                    73px 997px 0 0px rgba(255, 255, 255, 0.422),
                    1642px 216px 0 0px rgba(255, 255, 255, 0.909),
                    1442px 1405px 0 0px rgba(255, 255, 255, 0.187),
                    1316px 1474px 0 0px rgba(255, 255, 255, 0.04),
                    1748px 569px 0 0px rgba(255, 255, 255, 0.105),
                    1083px 1502px 0 0px rgba(255, 255, 255, 0.621),
                    46px 168px 0 0px rgba(255, 255, 255, 0.934),
                    424px 1017px 0 0px rgba(255, 255, 255, 0.363),
                    729px 1148px 0 0px rgba(255, 255, 255, 0.575),
                    677px 1749px 0 0px rgba(255, 255, 255, 0.554),
                    868px 918px 0 0px rgba(255, 255, 255, 0.847),
                    1091px 379px 0 0px rgba(255, 255, 255, 0.966),
                    1776px 515px 0 0px rgba(255, 255, 255, 0.175),
                    1300px 561px 0 0px rgba(255, 255, 255, 0.104),
                    1371px 1359px 0 0px rgba(255, 255, 255, 0.584),
                    1159px 413px 0 0px rgba(255, 255, 255, 0.831),
                    1543px 1702px 0 0px rgba(255, 255, 255, 0.252),
                    195px 1180px 0 0px rgba(255, 255, 255, 0.532),
                    329px 1698px 0 0px rgba(255, 255, 255, 0.163),
                    1573px 1363px 0 0px rgba(255, 255, 255, 0.497),
                    1583px 1396px 0 0px rgba(255, 255, 255, 0.792),
                    1567px 1449px 0 0px rgba(255, 255, 255, 0.955),
                    860px 722px 0 0px rgba(255, 255, 255, 0.824),
                    1371px 1219px 0 0px rgba(255, 255, 255, 0.733),
                    1172px 1589px 0 0px rgba(255, 255, 255, 0.446),
                    282px 105px 0 0px rgba(255, 255, 255, 0.276),
                    560px 1477px 0 0px rgba(255, 255, 255, 0.449),
                    188px 472px 0 0px rgba(255, 255, 255, 0.288),
                    1167px 1569px 0 0px rgba(255, 255, 255, 0.6),
                    1072px 111px 0 0px rgba(255, 255, 255, 0.475),
                    1519px 245px 0 0px rgba(255, 255, 255, 0.907),
                    741px 767px 0 0px rgba(255, 255, 255, 0.903),
                    1774px 114px 0 0px rgba(255, 255, 255, 0.89),
                    1525px 14px 0 0px rgba(255, 255, 255, 0.506),
                    165px 848px 0 0px rgba(255, 255, 255, 0.361),
                    1112px 1509px 0 0px rgba(255, 255, 255, 0.429),
                    931px 802px 0 0px rgba(255, 255, 255, 0.529),
                    1730px 1209px 0 0px rgba(255, 255, 255, 0.801),
                    777px 1703px 0 0px rgba(255, 255, 255, 0.776),
                    511px 1053px 0 0px rgba(255, 255, 255, 0.536),
                    673px 254px 0 0px rgba(255, 255, 255, 0.321),
                    681px 1432px 0 0px rgba(255, 255, 255, 0.045),
                    1385px 1047px 0 0px rgba(255, 255, 255, 0.726),
                    1683px 222px 0 0px rgba(255, 255, 255, 0.002),
                    1011px 199px 0 0px rgba(255, 255, 255, 0.858),
                    1114px 1675px 0 0px rgba(255, 255, 255, 0.203),
                    824px 634px 0 0px rgba(255, 255, 255, 0.092),
                    811px 1252px 0 0px rgba(255, 255, 255, 0.398),
                    418px 1434px 0 0px rgba(255, 255, 255, 0.312),
                    1406px 88px 0 0px rgba(255, 255, 255, 0.954),
                    1123px 569px 0 0px rgba(255, 255, 255, 0.303),
                    1278px 996px 0 0px rgba(255, 255, 255, 0.87),
                    787px 547px 0 0px rgba(255, 255, 255, 0.673),
                    1538px 1118px 0 0px rgba(255, 255, 255, 0.15),
                    1219px 503px 0 0px rgba(255, 255, 255, 0.358),
                    742px 46px 0 0px rgba(255, 255, 255, 0.426),
                    1496px 837px 0 0px rgba(255, 255, 255, 0.387),
                    418px 193px 0 0px rgba(255, 255, 255, 0.366),
                    1547px 1086px 0 0px rgba(255, 255, 255, 0.532),
                    1513px 601px 0 0px rgba(255, 255, 255, 0.818),
                    188px 310px 0 0px rgba(255, 255, 255, 0.605),
                    1541px 920px 0 0px rgba(255, 255, 255, 0.578),
                    1112px 727px 0 0px rgba(255, 255, 255, 0.761),
                    826px 1548px 0 0px rgba(255, 255, 255, 0.084),
                    1572px 1240px 0 0px rgba(255, 255, 255, 0.801),
                    276px 111px 0 0px rgba(255, 255, 255, 0.147),
                    1750px 1463px 0 0px rgba(255, 255, 255, 0.434),
                    501px 720px 0 0px rgba(255, 255, 255, 0.742),
                    641px 1524px 0 0px rgba(255, 255, 255, 0.769),
                    470px 1657px 0 0px rgba(255, 255, 255, 0.943),
                    1776px 1113px 0 0px rgba(255, 255, 255, 0.539),
                    1411px 901px 0 0px rgba(255, 255, 255, 0.989),
                    140px 945px 0 0px rgba(255, 255, 255, 0.348),
                    847px 1689px 0 0px rgba(255, 255, 255, 0.375),
                    35px 689px 0 0px rgba(255, 255, 255, 0.454),
                    1314px 1248px 0 0px rgba(255, 255, 255, 0.275),
                    458px 1719px 0 0px rgba(255, 255, 255, 0.461),
                    470px 416px 0 0px rgba(255, 255, 255, 0.246),
                    726px 695px 0 0px rgba(255, 255, 255, 0.804),
                    205px 1189px 0 0px rgba(255, 255, 255, 0.806),
                    1001px 730px 0 0px rgba(255, 255, 255, 0.066),
                    284px 1601px 0 0px rgba(255, 255, 255, 0.24),
                    1172px 1485px 0 0px rgba(255, 255, 255, 0.687),
                    1605px 933px 0 0px rgba(255, 255, 255, 0.35),
                    1767px 599px 0 0px rgba(255, 255, 255, 0.565),
                    257px 546px 0 0px rgba(255, 255, 255, 0.573),
                    74px 1041px 0 0px rgba(255, 255, 255, 0.581),
                    209px 321px 0 0px rgba(255, 255, 255, 0.483),
                    1767px 1748px 0 0px rgba(255, 255, 255, 0.591),
                    1544px 1018px 0 0px rgba(255, 255, 255, 0.86),
                    960px 1101px 0 0px rgba(255, 255, 255, 0.952),
                    627px 835px 0 0px rgba(255, 255, 255, 0.121),
                    1337px 558px 0 0px rgba(255, 255, 255, 0.727),
                    1699px 649px 0 0px rgba(255, 255, 255, 0.596),
                    1175px 1130px 0 0px rgba(255, 255, 255, 0.769),
                    991px 1572px 0 0px rgba(255, 255, 255, 0.541),
                    714px 774px 0 0px rgba(255, 255, 255, 0.61),
                    96px 1586px 0 0px rgba(255, 255, 255, 0.984),
                    706px 715px 0 0px rgba(255, 255, 255, 0.44),
                    765px 405px 0 0px rgba(255, 255, 255, 0.39),
                    1643px 1092px 0 0px rgba(255, 255, 255, 0.122),
                    496px 436px 0 0px rgba(255, 255, 255, 0.999),
                    17px 667px 0 0px rgba(255, 255, 255, 0.105),
                    384px 978px 0 0px rgba(255, 255, 255, 0.614),
                    1156px 1280px 0 0px rgba(255, 255, 255, 0.758),
                    1676px 1370px 0 0px rgba(255, 255, 255, 0.012),
                    1591px 355px 0 0px rgba(255, 255, 255, 0.07),
                    1275px 696px 0 0px rgba(255, 255, 255, 0.865),
                    1058px 1753px 0 0px rgba(255, 255, 255, 0.884),
                    1769px 539px 0 0px rgba(255, 255, 255, 0.295),
                    1363px 465px 0 0px rgba(255, 255, 255, 0.554),
                    163px 1402px 0 0px rgba(255, 255, 255, 0.33),
                    20px 935px 0 0px rgba(255, 255, 255, 0.036),
                    1648px 1101px 0 0px rgba(255, 255, 255, 0.682),
                    797px 1382px 0 0px rgba(255, 255, 255, 0.483),
                    1136px 24px 0 0px rgba(255, 255, 255, 0.409),
                    1185px 1248px 0 0px rgba(255, 255, 255, 0.209),
                    1583px 554px 0 0px rgba(255, 255, 255, 0.353),
                    144px 976px 0 0px rgba(255, 255, 255, 0.496),
                    1143px 405px 0 0px rgba(255, 255, 255, 0.052),
                    1176px 1137px 0 0px rgba(255, 255, 255, 0.931),
                    96px 1357px 0 0px rgba(255, 255, 255, 0.301),
                    1229px 276px 0 0px rgba(255, 255, 255, 0.179),
                    45px 1550px 0 0px rgba(255, 255, 255, 0.077),
                    356px 1192px 0 0px rgba(255, 255, 255, 0.551),
                    1179px 65px 0 0px rgba(255, 255, 255, 0.582),
                    638px 1332px 0 0px rgba(255, 255, 255, 0.09),
                    141px 375px 0 0px rgba(255, 255, 255, 0.096),
                    34px 462px 0 0px rgba(255, 255, 255, 0.242),
                    271px 1750px 0 0px rgba(255, 255, 255, 0.556),
                    1608px 58px 0 0px rgba(255, 255, 255, 0.593),
                    350px 1498px 0 0px rgba(255, 255, 255, 0.269),
                    569px 1747px 0 0px rgba(255, 255, 255, 0.609),
                    1116px 322px 0 0px rgba(255, 255, 255, 0.158),
                    878px 455px 0 0px rgba(255, 255, 255, 0.386),
                    203px 703px 0 0px rgba(255, 255, 255, 0.511),
                    213px 801px 0 0px rgba(255, 255, 255, 0.707),
                    247px 1543px 0 0px rgba(255, 255, 255, 0.03),
                    1084px 1798px 0 0px rgba(255, 255, 255, 0.258),
                    1329px 1251px 0 0px rgba(255, 255, 255, 0.354),
                    407px 238px 0 0px rgba(255, 255, 255, 0.503),
                    616px 361px 0 0px rgba(255, 255, 255, 0.328),
                    1198px 394px 0 0px rgba(255, 255, 255, 0.833),
                    798px 1383px 0 0px rgba(255, 255, 255, 0.234),
                    217px 132px 0 0px rgba(255, 255, 255, 0.192),
                    1530px 1216px 0 0px rgba(255, 255, 255, 0.788),
                    1581px 752px 0 0px rgba(255, 255, 255, 0.414),
                    1212px 122px 0 0px rgba(255, 255, 255, 0.934),
                    408px 1472px 0 0px rgba(255, 255, 255, 0.643),
                    1384px 790px 0 0px rgba(255, 255, 255, 0.525),
                    198px 168px 0 0px rgba(255, 255, 255, 0.543),
                    317px 711px 0 0px rgba(255, 255, 255, 0.438),
                    1385px 872px 0 0px rgba(255, 255, 255, 0.807),
                    6px 1746px 0 0px rgba(255, 255, 255, 0.531),
                    1589px 478px 0 0px rgba(255, 255, 255, 0.376),
                    907px 373px 0 0px rgba(255, 255, 255, 0.761),
                    995px 821px 0 0px rgba(255, 255, 255, 0.682),
                    1345px 223px 0 0px rgba(255, 255, 255, 0.991),
                    1465px 651px 0 0px rgba(255, 255, 255, 0.629),
                    313px 978px 0 0px rgba(255, 255, 255, 0.503),
                    829px 824px 0 0px rgba(255, 255, 255, 0.937),
                    757px 730px 0 0px rgba(255, 255, 255, 0.522),
                    969px 1012px 0 0px rgba(255, 255, 255, 0.645),
                    240px 706px 0 0px rgba(255, 255, 255, 0.547),
                    1272px 688px 0 0px rgba(255, 255, 255, 0.694),
                    1506px 1144px 0 0px rgba(255, 255, 255, 0.569),
                    634px 1545px 0 0px rgba(255, 255, 255, 0.412),
                    1221px 964px 0 0px rgba(255, 255, 255, 0.837),
                    228px 1383px 0 0px rgba(255, 255, 255, 0.749),
                    665px 577px 0 0px rgba(255, 255, 255, 0.827),
                    456px 460px 0 0px rgba(255, 255, 255, 0.446),
                    635px 415px 0 0px rgba(255, 255, 255, 0.47),
                    1421px 690px 0 0px rgba(255, 255, 255, 0.322),
                    93px 1534px 0 0px rgba(255, 255, 255, 0.594),
                    674px 765px 0 0px rgba(255, 255, 255, 0.714),
                    1580px 420px 0 0px rgba(255, 255, 255, 0.359),
                    1533px 182px 0 0px rgba(255, 255, 255, 0.112),
                    477px 1681px 0 0px rgba(255, 255, 255, 0.313),
                    1255px 1039px 0 0px rgba(255, 255, 255, 0.452),
                    1709px 1335px 0 0px rgba(255, 255, 255, 0.354),
                    743px 900px 0 0px rgba(255, 255, 255, 0.278),
                    839px 361px 0 0px rgba(255, 255, 255, 0.931),
                    357px 1481px 0 0px rgba(255, 255, 255, 0.709),
                    1060px 1060px 0 0px rgba(255, 255, 255, 0.97),
                    1186px 543px 0 0px rgba(255, 255, 255, 0.256),
                    1366px 1029px 0 0px rgba(255, 255, 255, 0.478),
                    331px 377px 0 0px rgba(255, 255, 255, 0.334),
                    1123px 771px 0 0px rgba(255, 255, 255, 0.21),
                    170px 524px 0 0px rgba(255, 255, 255, 0.948),
                    209px 922px 0 0px rgba(255, 255, 255, 0.176),
                    1593px 987px 0 0px rgba(255, 255, 255, 0.148),
                    327px 526px 0 0px rgba(255, 255, 255, 0.103),
                    93px 1350px 0 0px rgba(255, 255, 255, 0.516),
                    831px 300px 0 0px rgba(255, 255, 255, 0.701),
                    938px 469px 0 0px rgba(255, 255, 255, 0.759),
                    1287px 1645px 0 0px rgba(255, 255, 255, 0.732),
                    1699px 1646px 0 0px rgba(255, 255, 255, 0.65),
                    1690px 1665px 0 0px rgba(255, 255, 255, 0.659),
                    196px 492px 0 0px rgba(255, 255, 255, 0.679),
                    1735px 1500px 0 0px rgba(255, 255, 255, 0.051),
                    265px 91px 0 0px rgba(255, 255, 255, 0.545),
                    1283px 1654px 0 0px rgba(255, 255, 255, 0.664),
                    1270px 770px 0 0px rgba(255, 255, 255, 0.178),
                    1392px 952px 0 0px rgba(255, 255, 255, 0.68),
                    1401px 992px 0 0px rgba(255, 255, 255, 0.261),
                    261px 141px 0 0px rgba(255, 255, 255, 0.188),
                    33px 797px 0 0px rgba(255, 255, 255, 0.784),
                    732px 556px 0 0px rgba(255, 255, 255, 0.52),
                    584px 1129px 0 0px rgba(255, 255, 255, 0.498),
                    234px 1442px 0 0px rgba(255, 255, 255, 0.286),
                    1384px 1227px 0 0px rgba(255, 255, 255, 0.983),
                    262px 865px 0 0px rgba(255, 255, 255, 0.962),
                    1038px 968px 0 0px rgba(255, 255, 255, 0.688),
                    110px 792px 0 0px rgba(255, 255, 255, 0.351),
                    569px 1701px 0 0px rgba(255, 255, 255, 0.176),
                    1623px 334px 0 0px rgba(255, 255, 255, 0.394),
                    1566px 1662px 0 0px rgba(255, 255, 255, 0.997),
                    1559px 1015px 0 0px rgba(255, 255, 255, 0.732),
                    876px 1705px 0 0px rgba(255, 255, 255, 0.227),
                    1443px 981px 0 0px rgba(255, 255, 255, 0.476),
                    889px 1585px 0 0px rgba(255, 255, 255, 0.447),
                    438px 1478px 0 0px rgba(255, 255, 255, 0.213),
                    20px 1250px 0 0px rgba(255, 255, 255, 0.727),
                    600px 702px 0 0px rgba(255, 255, 255, 0.897),
                    749px 1294px 0 0px rgba(255, 255, 255, 0.185),
                    1626px 1207px 0 0px rgba(255, 255, 255, 0.421),
                    545px 889px 0 0px rgba(255, 255, 255, 0.547),
                    851px 987px 0 0px rgba(255, 255, 255, 0.147),
                    1264px 660px 0 0px rgba(255, 255, 255, 0.199),
                    346px 1047px 0 0px rgba(255, 255, 255, 0.556),
                    229px 51px 0 0px rgba(255, 255, 255, 0.186),
                    37px 956px 0 0px rgba(255, 255, 255, 0.993),
                    998px 659px 0 0px rgba(255, 255, 255, 0.457),
                    455px 1103px 0 0px rgba(255, 255, 255, 0.193),
                    191px 1218px 0 0px rgba(255, 255, 255, 0.09),
                    401px 104px 0 0px rgba(255, 255, 255, 0.591),
                    1148px 1511px 0 0px rgba(255, 255, 255, 0.448),
                    1226px 1750px 0 0px rgba(255, 255, 255, 0.367),
                    302px 464px 0 0px rgba(255, 255, 255, 0.461),
                    571px 628px 0 0px rgba(255, 255, 255, 0.991),
                    1184px 1292px 0 0px rgba(255, 255, 255, 0.67),
                    879px 1636px 0 0px rgba(255, 255, 255, 0.653),
                    606px 1543px 0 0px rgba(255, 255, 255, 0.175),
                    580px 1259px 0 0px rgba(255, 255, 255, 0.899),
                    174px 1132px 0 0px rgba(255, 255, 255, 0.799),
                    484px 1400px 0 0px rgba(255, 255, 255, 0.122),
                    1786px 873px 0 0px rgba(255, 255, 255, 0.638),
                    1171px 196px 0 0px rgba(255, 255, 255, 0.763),
                    593px 289px 0 0px rgba(255, 255, 255, 0.24),
                    1175px 128px 0 0px rgba(255, 255, 255, 0.776),
                    902px 1693px 0 0px rgba(255, 255, 255, 0.728),
                    293px 1615px 0 0px rgba(255, 255, 255, 0.007),
                    814px 1170px 0 0px rgba(255, 255, 255, 0.224),
                    1304px 1742px 0 0px rgba(255, 255, 255, 0.343),
                    718px 1656px 0 0px rgba(255, 255, 255, 0.58),
                    358px 1302px 0 0px rgba(255, 255, 255, 0.469),
                    646px 933px 0 0px rgba(255, 255, 255, 0.702),
                    34px 1316px 0 0px rgba(255, 255, 255, 0.153),
                    555px 286px 0 0px rgba(255, 255, 255, 0.769),
                    58px 320px 0 0px rgba(255, 255, 255, 0.985),
                    599px 1463px 0 0px rgba(255, 255, 255, 0.614),
                    806px 895px 0 0px rgba(255, 255, 255, 0.241),
                    1492px 1040px 0 0px rgba(255, 255, 255, 0.172),
                    1175px 551px 0 0px rgba(255, 255, 255, 0.549),
                    1765px 1122px 0 0px rgba(255, 255, 255, 0.272),
                    737px 1467px 0 0px rgba(255, 255, 255, 0.438),
                    1687px 1759px 0 0px rgba(255, 255, 255, 0.348),
                    1746px 974px 0 0px rgba(255, 255, 255, 0.734),
                    1665px 333px 0 0px rgba(255, 255, 255, 0.838),
                    434px 950px 0 0px rgba(255, 255, 255, 0.403),
                    1396px 1210px 0 0px rgba(255, 255, 255, 0.731),
                    969px 378px 0 0px rgba(255, 255, 255, 0.69),
                    797px 33px 0 0px rgba(255, 255, 255, 0.448),
                    952px 238px 0 0px rgba(255, 255, 255, 0.564),
                    73px 1419px 0 0px rgba(255, 255, 255, 0.136),
                    125px 1441px 0 0px rgba(255, 255, 255, 0.141),
                    153px 243px 0 0px rgba(255, 255, 255, 0.646),
                    418px 1265px 0 0px rgba(255, 255, 255, 0.467),
                    1358px 319px 0 0px rgba(255, 255, 255, 0.968),
                    382px 608px 0 0px rgba(255, 255, 255, 0.895),
                    1431px 1114px 0 0px rgba(255, 255, 255, 0.816),
                    179px 1115px 0 0px rgba(255, 255, 255, 0.462),
                    341px 453px 0 0px rgba(255, 255, 255, 0.529),
                    775px 1737px 0 0px rgba(255, 255, 255, 0.156),
                    48px 1704px 0 0px rgba(255, 255, 255, 0.734),
                    885px 340px 0 0px rgba(255, 255, 255, 0.14),
                    1440px 52px 0 0px rgba(255, 255, 255, 0.355),
                    307px 254px 0 0px rgba(255, 255, 255, 0.596),
                    327px 1303px 0 0px rgba(255, 255, 255, 0.679),
                    1067px 512px 0 0px rgba(255, 255, 255, 0.326),
                    1088px 224px 0 0px rgba(255, 255, 255, 0.456),
                    21px 1490px 0 0px rgba(255, 255, 255, 0.979),
                    1726px 707px 0 0px rgba(255, 255, 255, 0.322),
                    915px 1599px 0 0px rgba(255, 255, 255, 0.918),
                    998px 345px 0 0px rgba(255, 255, 255, 0.073),
                    1339px 1515px 0 0px rgba(255, 255, 255, 0.428),
                    477px 180px 0 0px rgba(255, 255, 255, 0.044),
                    1776px 571px 0 0px rgba(255, 255, 255, 0.776),
                    283px 267px 0 0px rgba(255, 255, 255, 0.257),
                    486px 1546px 0 0px rgba(255, 255, 255, 0.313),
                    1192px 1686px 0 0px rgba(255, 255, 255, 0.133),
                    1626px 689px 0 0px rgba(255, 255, 255, 0.659),
                    22px 58px 0 0px rgba(255, 255, 255, 0.27),
                    138px 1112px 0 0px rgba(255, 255, 255, 0.073),
                    665px 1558px 0 0px rgba(255, 255, 255, 0.744),
                    246px 119px 0 0px rgba(255, 255, 255, 0.273),
                    918px 49px 0 0px rgba(255, 255, 255, 0.728),
                    967px 1538px 0 0px rgba(255, 255, 255, 0.571),
                    676px 1788px 0 0px rgba(255, 255, 255, 0.526),
                    1722px 517px 0 0px rgba(255, 255, 255, 0.195),
                    858px 1696px 0 0px rgba(255, 255, 255, 0.369),
                    616px 448px 0 0px rgba(255, 255, 255, 0.997),
                    1426px 1165px 0 0px rgba(255, 255, 255, 0.157),
                    1638px 1207px 0 0px rgba(255, 255, 255, 0.183),
                    650px 1399px 0 0px rgba(255, 255, 255, 0.548),
                    1522px 572px 0 0px rgba(255, 255, 255, 0.066),
                    1329px 1407px 0 0px rgba(255, 255, 255, 0.462),
                    59px 755px 0 0px rgba(255, 255, 255, 0.615),
                    1128px 792px 0 0px rgba(255, 255, 255, 0.812),
                    520px 514px 0 0px rgba(255, 255, 255, 0.582),
                    1520px 546px 0 0px rgba(255, 255, 255, 0.056),
                    1238px 1153px 0 0px rgba(255, 255, 255, 0.695),
                    796px 1067px 0 0px rgba(255, 255, 255, 0.536),
                    351px 385px 0 0px rgba(255, 255, 255, 0.023),
                    322px 1376px 0 0px rgba(255, 255, 255, 0.622),
                    531px 164px 0 0px rgba(255, 255, 255, 0.296),
                    1354px 1158px 0 0px rgba(255, 255, 255, 0.14),
                    1568px 1777px 0 0px rgba(255, 255, 255, 0.04),
                    227px 1773px 0 0px rgba(255, 255, 255, 0.912),
                    623px 600px 0 0px rgba(255, 255, 255, 0.404),
                    128px 939px 0 0px rgba(255, 255, 255, 0.028),
                    1238px 1007px 0 0px rgba(255, 255, 255, 0.954),
                    466px 712px 0 0px rgba(255, 255, 255, 0.71),
                    518px 1667px 0 0px rgba(255, 255, 255, 0.042),
                    1057px 1711px 0 0px rgba(255, 255, 255, 0.309),
                    1492px 716px 0 0px rgba(255, 255, 255, 0.474),
                    1759px 1790px 0 0px rgba(255, 255, 255, 0.531),
                    744px 1041px 0 0px rgba(255, 255, 255, 0.545),
                    1238px 128px 0 0px rgba(255, 255, 255, 0.059),
                    22px 1241px 0 0px rgba(255, 255, 255, 0.967);
                border-radius: 100px;
        }
    }
    

`;

const Background = () => {
    const [isMounted, setIsMounted] = useState(false)
    const prefersReducedMotion = usePrefersReducedMotion()

    useEffect(() => {
        if (prefersReducedMotion) return
    
        const timeout = setTimeout(() => setIsMounted(true), navDelay)
        return () => clearTimeout(timeout)
      })



	return (
		<StyledBackgroundDiv>
			<TransitionGroup component={null}>
                {isMounted ?
				<CSSTransition classNames="fade" timeout={loaderDelay}>
					<div style={{ transitionDelay: `0ms` }} className="SolarSystem">
						<div className="solar-system">
							<div className="sun"></div>
							<div className="mercury"></div>
							<div className="venus"></div>
							<div className="earth"></div>
							<div className="mars"></div>
							<div className="jupiter"></div>
							<div className="saturn"></div>
							<div className="uranus"></div>
							<div className="neptune"></div>
							<div className="pluto"></div>
							<div className="asteroid-belt"></div>
						</div>
					</div>
				</CSSTransition>
                :''}
			</TransitionGroup>
		</StyledBackgroundDiv>
	);
};

export default Background;
