import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { srConfig } from "../../config";
import sr from "../../utils/sr";
import usePrefersReducedMotion from "../../hooks/usePrefersReducedMotion";
import mixins from "../../styles/mixins";
import emailjs from '@emailjs/browser'
import resume from '../../static/resume.pdf'

const StyledContactSection = styled.section`
	max-width: 600px;
	margin: 0 auto 100px;
	text-align: center;

	@media (max-width: 768px) {
		margin: 0 auto 50px;
	}

	.overline {
		display: block;
		margin-bottom: 20px;
		color: var(--green);
		font-family: var(--font-mono);
		font-size: var(--fz-md);
		font-weight: 400;

		&:before {
			bottom: 0;
			font-size: var(--fz-sm);
		}

		&:after {
			display: none;
		}
	}

	.title {
		font-size: clamp(40px, 5vw, 60px);
	}

	.resume-button {
		&::after {
			height: 2px;
			bottom: 3px;
			opacity: 1;
		}
	}

	form {
		width: 85%;
		margin: auto;
	}
    ul {
        list-style: none;
        padding-left: 0;
    }
	ul li {
		margin: 0 0 10px 0;
		padding: 0;
		display: block;
		clear: both;
		position: relative;
	}
    .half {
        width: 49%;
        float: left;
        clear: none; 
    }
	ul li.half:nth-of-type(2) {
		margin-left: 2%;
	}
	form input[type='text'],
	form input[type='email'],
	form textarea {
		width: 100%;
		height: 40px;
		font-size: 16px;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		background-color: var(--light-1-bg);
		color: var(--slate);
		border-color: transparent;
		font-family: var(--font-sans);
	}
	form input::placeholder,
	form textarea::placeholder {
		color: var(--green);
	}
	form input[type='text'],
	form input[type='email'] {
		padding: 0 20px;
	}
	form textarea {
		min-width: 100%;
		max-width: 100%;
		padding: 20px;
		min-height: 150px;
	}
	label {
		&::before,
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0%;
			height: 2px;
			transition-duration: 0.5s;
			transition-property: all;
			transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
			transition-delay: 0s;
		}
	}
	input:focus + label::before,
	textarea:focus + label::before{
		width: 100%;
		background: var(--green);
	}
	.error label::after {
		width: 100%;
		background: red;
	}
	.err-msg {
		min-height: 26px;
		marign: 0;
	}
	li button {
		${mixins.bigButton[0]}
	}
	.hidden {
		display: none;
	}
	.toast {
		height: 510px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		h3 {
			color: var(--green);
		}
	}
`;

const Contact = () => {
	const [message, setMessage] = useState({
		contact: "",
		email: "",
		subject: "",
		message: "",
	});
	const [emailErr, setEmailErr] = useState(false);
	const [messageErr, setMessageErr] = useState(false);
	const [errText, setErrText] = useState("");
	const [msgSent, setMsgSent] = useState(false)

	const revealContainer = useRef(null);
	const prefersReducedMotion = usePrefersReducedMotion();

	useEffect(() => {
		if (prefersReducedMotion) {
			return;
		}

		sr.reveal(revealContainer.current, srConfig());
	}, []);

	const handleChange = (e) => {
		setMessage((message) => ({ ...message, [e.target.name]: e.target.value }));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(message.email)) {
			setEmailErr(true);
			if (!message.message) {
				setMessageErr(true);
				setErrText("Please enter a valid email and message");
			} else {
				setErrText("Please enter a valid email");
			}
		}
		if (
			/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(message.email) &&
			!message.message
		) {
			setMessageErr(true);
			setErrText("Please enter a message");
		}
		if (
			/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(message.email) &&
			message.message
		) {
			console.log(message);
			emailjs.send('service_ycgqsro', 'template_7272nep1', message, '971nXfaSnfei0RNzx').then(res => {
				setMessage({
					contact: '',
					email: '',
					subject: '',
					message: '',
				})
				setMsgSent(true)
			}, error => {
				console.error(error)
			})
		}
	};

	return (
		<StyledContactSection id="contact" ref={revealContainer}>
			<h2 className="numbered-heading overline">Want To Say Hi?</h2>

			<h2 className="title">Get In Touch</h2>

			<p>
				I'm always interested in new opportunities - especially ambitious or
				large projects. If you have a question or request, don't hesitate to use
				the form below.
			</p>
			<p>
				For other job opportunities, skip the wait and view or download{" "}
				<a
					className="resume-button"
					href={resume}
					target="_blank"
					rel="noopener noreferrer"
				>
					my resume
				</a>
			</p>

			<form onSubmit={handleSubmit} autoComplete="off" noValidate className={msgSent ? "hidden" : null}>
				<ul>
					<li className="half">
						<input
							type="text"
							name="contact"
							placeholder="Name"
							value={message.contact}
							onChange={handleChange}
						/>
						<label className="input-label"></label>
					</li>
					<li className={emailErr && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(message.email) ? "half required error" : "half required"}>
						<input
							type="text"
							name="email"
							placeholder="Email"
							value={message.email}
							onChange={handleChange}
							pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
							required
						/>
						<label className="input-label"></label>
					</li>
					<li>
						<input
							type="text"
							name="subject"
							placeholder="Subject"
							value={message.subject}
							onChange={handleChange}
						/>
						<label className="input-label"></label>
					</li>
					<li className={messageErr && !message.message ? "required error" : "required"}>
						<textarea
							type="text"
							name="message"
							placeholder="Message"
							value={message.message}
							onChange={handleChange}
							required
						/>
						<label className="input-label"></label>
					</li>
					<li className="err-msg">{errText ? errText : null}</li>
					<li>
						<button onClick={handleSubmit}>Send Message!</button>
					</li>
				</ul>
			</form>
			{msgSent ? <div className="toast">
				<h3>Thanks for the message!</h3>
				<p>I will do my best to respond promptly</p>
			</div> : null}
		</StyledContactSection>
	);
};

export default Contact;
