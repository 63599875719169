import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { srConfig } from "../../config";
import sr from "../../utils/sr";
import mixins from "../../styles/mixins";

const StyledAboutSection = styled.section`
	max-width: 900px;

	.inner {
		display: grid;
		grid-template-columns: 3fr 2fr;
		grid-gap: 50px;

		@media (max-width: 768px) {
			display: block;
		}

		.canvas-container {
			font-family: "Orbitron", sans-serif;
		}
	}
`;
const StyledText = styled.div`
	ul.skills-list {
		display: grid;
		grid-template-columns: repeat(2, minmax(140px, 200px));
		grid-gap: 0 10px;
		padding: 0;
		margin: 20px 0 0 0;
		overflow: hidden;
		list-style: none;

		li {
			position: relative;
			margin-bottom: 10px;
			padding-left: 20px;
			font-family: var(--font-mono);
			font-size: var(--fz-xs);

			&:before {
				content: "▹";
				position: absolute;
				left: 0;
				color: var(--green);
				font-size: var(--fz-sm);
				line-height: 12px;
			}
		}
	}
`;
const StyledPic = styled.div`
	position: relative;
	max-width: 300px;

	@media (max-width: 768px) {
		margin: 50px auto 0;
		width: 70%;
	}

	.wrapper {
		${mixins.boxShadow[0]};
		display: block;
		position: relative;
		width: 100%;
		border-radius: var(--border-radius);
		background-color: var(--green);

		&:hover,
		&:focus {
			outline: 0;

			&:after {
				top: 15px;
				left: 15px;
			}

			.img {
				filter: none;
				mix-blend-mode: normal;
			}
		}

		.img {
			position: relative;
			border-radius: var(--border-radius);
			mix-blend-mode: multiply;
			filter: grayscale(100%) contrast(1);
			transition: var(--transition);
		}

		&:before,
		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: var(--border-radius);
			transition: var(--transition);
		}

		&:before {
			top: 0;
			left: 0;
			background-color: var(--navy);
			mix-blend-mode: screen;
		}

		&:after {
			border: 2px solid var(--green);
			top: 20px;
			left: 20px;
			z-index: -1;
		}
	}
`;

const tech = [
	{title: "Git"},
	{title: "JavaScript"},
	{title: "TypeScript"},
	{title: "CSS"},
	{title: "HTML"},
	{title: "React"},
	{title: "Python"},
	{title: "Django"},
	{title: "PostgreSQL"},
	{title: "MongoDB"},
	{title: "Node.js"},
	{title: "Express" },
	{title: "AWS" },
];

const About = () => {
	const revealContainer = useRef(null);
	// const [dark, setDark] = useState(
	// 	window.matchMedia("(prefers-color-scheme: dark)").matches
	// );

	// let textColour = dark ? "#64ffcd" : "#000000"

	useEffect(() => {
		sr.reveal(revealContainer.current, srConfig());
	}, []);

	useEffect(() => {
		console.log("Loading TagCanvas...");
		const TagCanvas = window.TagCanvas;
		const tagCanvasOptions = {
			textColour: "#64ffcd",
			imageMode: "both",
			fadeIn: 1000,
			shadow: "#666",
			textHeight: 35,
			noSelect: true,
			shape: "sphere",
			maxSpeed: 0.06,
			wheelZoom: false,
			zoom: 0.95,
			initial: [-0.3, 0.05],
		};
		try {
			TagCanvas.Start("tech-canvas", "taglist", tagCanvasOptions);
		} catch (e) {
			console.log("Canvas error.");
			console.log(e);
		}
	}, []);

	return (
		<StyledAboutSection id="about" ref={revealContainer}>
			<h2 className="numbered-heading">About Me</h2>

			<div className="inner">
				<StyledText>
					<div>
						<p>
							I'm a full-stack software developer based in Toronto, Canada. I
							have a love of problem solving and developing projects that can
							help people in small or large ways.
						</p>

						<p>
							Fan of sports, including the Toronto Maple Leafs (unfortunately)
							and Toronto Blue Jays, and a passion for fitness. A former Tennis
							Pro and coach, I still enjoy playing whenever possible.
						</p>

						<p>
							Always interested in expanding my knowledge especially about
							software engineering, web development, or space and astronomy. I
							enjoy working on ambitious projects with positive people.
						</p>
					</div>
				</StyledText>

				<div className="canvas-container">
					<canvas
						id="tech-canvas"
						width="1000px"
						height="1000px"
						style={{
							maxWidth: "1000px",
							width: "100%",
							position: "relative",
							margin: "0 auto",
						}}
						className="to-fade-in fast-anim"
					></canvas>
					<div id="taglist" style={{ display: "none" }}>
						<ul>
							{tech.map((t) => (
								<li key={t.title}>
									<a>{t.title}</a>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</StyledAboutSection>
	);
};

export default About;
