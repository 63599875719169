import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import NotFoundPage from './pages/404';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';


if (process.env.NODE_ENV === 'production') disableReactDevTools()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <React.StrictMode>
    <Routes>
      <Route path='/' element={<App />}/>
      <Route path='/404' element={<NotFoundPage/>}/>
      <Route path='*' element={<Navigate to='/' replace />}/>
    </Routes>
  </React.StrictMode>
  </BrowserRouter>
);
