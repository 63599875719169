import { css } from "styled-components";

import OrbitronRegularWoff from '../fonts/Orbitron/Orbitron-Regular.woff'
import OrbitronSemiBoldWoff from '../fonts/Orbitron/Orbitron-SemiBold.woff'
import OrbitronBoldWoff from '../fonts/Orbitron/Orbitron-Bold.woff'
import OrbitronExtraBoldWoff from '../fonts/Orbitron/Orbitron-ExtraBold.woff'


const orbitronNormalWeights = {
    400: OrbitronRegularWoff,
    500: OrbitronSemiBoldWoff,
    600: OrbitronBoldWoff,
    700: OrbitronExtraBoldWoff
}

const orbitronNormal = {
    name: "Orbitron",
    normal: orbitronNormalWeights
}

const createFontFaces = (family, style = "normal") => {
    let styles = ""
    for (const [weight, formats] of Object.entries(family[style])) {
        const woff = formats

        styles += `
        @font-face {
            font-family: '${family.name}';
            src: url(${woff}) format('woff');
            font-weight: ${weight};
            font-style: ${style};
            font-display: auto;
        }`;
    }
    return styles
}

const orbitron = createFontFaces(orbitronNormal)

const Fonts = css`
${orbitron}
`;

export default Fonts;