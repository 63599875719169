import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import PropTypes from "prop-types";
import anime from "animejs";
import styled from "styled-components";
import IconLoader from "./icons/loader";
import mixins from "../styles/mixins";

const StyledLoader = styled.div`
	${mixins.flexCenter[0]};
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: var(--background);
	z-index: 99;

	.logo-wrapper {
		width: max-content;
		max-width: 100px;
		transition: var(--transition);
		opacity: ${(props) => (props.isMounted ? 1 : 0)};
		svg {
			display: block;
			width: 100%;
			height: 100%;
			margin: 0 auto;
			fill: none;
			user-select: none;
			#B {
				opacity: 0;
			}
		}
	}
	#top {
		animation: top 1.8s linear;
	}
	#bottom {
		animation: bottom 1.8s linear;
	}
`;

const Loader = ({ finishLoading }) => {
	const [isMounted, setIsMounted] = useState(false);

	const animate = () => {
		const loader = anime.timeline({
			complete: () => finishLoading(),
		});

		loader
			.add({
				targets: "#logo path",
				delay: 600,
				duration: 1500,
				easing: "easeInOutQuart",
				strokeDashoffset: [anime.setDashoffset, 0],
			})
			.add({
				targets: "#logo",
				duration: 700,
				easing: "easeInOutQuart",
				opacity: 1,
			})
			.add({
				targets: "#logo",
				delay: 500,
				duration: 300,
				easing: "easeInOutQuart",
				opacity: 0,
				scale: 0.1,
			})
			.add({
				targets: ".loader",
				duration: 200,
				easing: "easeInOutQuart",
				opacity: 0,
				zIndex: -1,
			});
	};

	useEffect(() => {
		const timeout = setTimeout(() => setIsMounted(true), 10);
		animate();
		return () => clearTimeout(timeout);
	}, []);

	return (
		<HelmetProvider>
			<StyledLoader className="loader" isMounted={isMounted}>
				<Helmet bodyAttributes={{ class: `hidden` }} />
				<div className="logo-wrapper">
					<IconLoader />
				</div>
			</StyledLoader>
		</HelmetProvider>
	);
};

Loader.propTypes = {
	finishLoading: PropTypes.func.isRequired,
};

export default Loader;
