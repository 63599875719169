import React from "react";

const IconLogo = () => (
	<svg
		id="logo"
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 447 471"
	>
		<title>Logo</title>
		<path
			d="M 0 0 L 447 0 L 447 170 L 308 170 L 308 336.5 L 307.5 337 L 267 337 L 267 130 L 407 130 L 407 40 L 40.5 40 L 40 40.5 L 40 130 L 0 130 L 0 0 Z "
			fill="currentColor"
		/>
		<path
			d="M 139 130 L 178.5 130 L 179 130.5 L 179 431 L 306.5 431 L 307 431.5 L 307 471 L 139 471 L 139 130 Z "
			fill="currentColor"
		/>
	</svg>
);

export default IconLogo;
